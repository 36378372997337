import javascript from '../assets/images/javascript.png';
import reactjs from '../assets/images/reactjs.png';
import html from '../assets/images/html.png';
import css from '../assets/images/css.png';
import java from '../assets/images/java.png';
import php from '../assets/images/php.png';
import nodejs from '../assets/images/nodejs.png';
import expressjs from '../assets/images/expressjs.png';
import mysql from '../assets/images/mysql.png';
import postgresql from '../assets/images/postgresql.png';
import mongodb from '../assets/images/mongodb.png';
import sql from '../assets/images/sql.png';

export const EDUCATION = {
	en:{
		/*
		school:"Nassau Community College",
		city: "Long Island",
		state: "New York",
		country: "United States",
		*/
		school:"University of the West Indies",
		city: "Pointe-à-Pitre",
		state: "",
		country: "Guadeloupe",
		title: "Computer science study",
		desc: "Studied and graduated from the University of the West Indies, while I was there I learned to program in different languages but for the most part it was Java. On my own time I taught myself PHP and JavaScript for my own websites and those languages would later help me begin my career as a web developer.",
		timeStart: "Past",
		timeEnd: "Past",
	},
	fr:{
		/*
		school:"Nassau Community College",
		city: "Long Island",
		state: "New York",
		country: "Etats Unis",
		*/
		school:"Université des Antilles",
		city: "Pointe-à-Pitre",
		state: "",
		country: "Guadeloupe",
		title: "Étude de science informatique",
		desc: "J'ai étudié et obtenu mon diplôme à l'Université des Antilles, pendant que j'y étais, j'ai aussi appris à programmer dans différentes langues de programmatiob, mais le plus souvent c'était Java. Pendant mon temps libre, j'ai appris PHP et JavaScript pour mes propres sites Web et ces langages m'ont ensuite aidé à commencer ma carrière de développeur Web.",
		timeStart: "Passé",
		timeEnd: "Passé",
	}
}
export const TECH_CAT = {
	tech: "Technical",
	techEnv: "Environments",
	techSkill: "Skills",
	ide: "IDE",
	frontend: "Front end",
	backend: "Back end",
	database: "Database",
	framework: "Framework",
	libs: "Libraries",
	version: "Version",
	system: "System",
	project: "Project",
	methodlogy: "Methodlogy",
	continuousintegration: "Continuous integration",
	test: "Test"
}
export const TECH_CAT_FR = {
	tech: "Technique",
	techEnv: "Environnements",
	techSkill: "Compétences",
	ide: "IDE",
	frontend: "Front end",
	backend: "Back end",
	database: "Base de données",
	framework: "Framework",
	libs: "Librairie",
	version: "Version",
	system: "Système",
	project: "Projet",
	methodlogy: "Méthodologie",
	continuousintegration: "Intégration continue",
	test: "Test"
}
export const TECH_LIST = {
	en:{
		frontend: "Front end",
		backend: "Back end",
		database: "Database",
		framework: "Framework",
		libs: "Libraries",
		test: "Test",
		ide: "IDE",
		version: "Version",
		system: "System",
		project: "Project management",
		continuousintegration: "Continuous integration",
		methodlogy: "Methodlogy",
	},
	fr:{
		frontend: "Front end",
		backend: "Back end",
		database: "Base de données",
		framework: "Framework",
		libs: "Librairie",
		test: "Test",
		ide: "IDE",
		version: "Version",
		system: "Système",
		project: "Gestion de projet",
		continuousintegration: "Intégration continue",
		methodlogy: "Méthodologie",
	}
}
export const TECH = {
	reactjs:"reactjs",
	java:"java",
	php:"php",
	javascript:"javascript",
	expressjs:"expressjs",
	mysql:"mysql",
	mongodb:"mongodb",
	sql:"sql",
	postgresql:"postgresql",
	jquery:"jquery",
	nodejs:"nodejs",
	css3:"css3",
	css:"css",
	html5:"html5",
	html:"html",
	typescript:"typescript",
	jest:"jest",
	bootstrap:"bootstrap",
	fontawesome:"font awesome",
	visualstudio:"visual studio",
	eclipse:"eclipse",
	notepad:"notepad++",
	netbeans:"netbeans",
	intellij:"intellij",
	phpstorm:"phpstorm",
	filezilla:"filezilla",
	git:"git",
	svn:"svn",
	azure:"azure",
	cpanel:"cpanel",
	phpmyadmin:"phpmyadmin",
	confluence:"confluence",
	jira:"jira",
	tuleap:"tuleap",
	redmine:"redmine",
	jenkins:"jenkins",
	swagger:"swagger",
	windows:"windows",
	macos:"macos",
	ubuntu:"ubuntu",
	centos:"centos",
	symfony:"symfony",
	codeigniter:"codeigniter",
	thymeleaf:"thymeleaf",
	joomla:"joomla",
	spring:"spring",
	springmvc:"spring mvc",
	springboot:"spring boot",
	excel:"excel",
	csv:"csv",
	pdf:"pdf",
	json:"json",
	word:"word",
	zend:"zend",
	unittest:"unit test",
	developer:"developer",
	frontend:"front end",
	backend:"back end",
	fullstack:"fullstack",
	tagcommander:"tagcommander",
	scrum:"scrum",
	mocha:"mochajs",
	phpunit:"phpunit",
	junit :"junit",
	springboottest :"springboottest",
	database :"database",
};
export const TECH_NM = {
	en:{
		reactjs:"React",
		java:"Java",
		php:"PHP",
		javascript:"JavaScript",
		expressjs:"ExpressJS",
		mysql:"MySQL",
		mongodb:"MongoDB",
		sql:"SQL",
		postgresql:"PostgreSQL",
		jquery:"jQuery",
		nodejs:"NodeJs",
		css3:"CSS3",
		css:"CSS",
		html5:"HTML5",
		html:"HTML",
		typescript:"TypeScript",
		jest:"Jest",
		bootstrap:"Bootstrap",
		fontawesome:"Font Awesome",
		visualstudio:"Visual Studio",
		eclipse:"Eclipse",
		notepad:"Notepad++",
		netbeans:"NetBeans",
		intellij:"IntelliJ",
		phpstorm:"PhpStorm",
		filezilla:"FileZilla",
		git:"Git",
		svn:"SVN",
		azure:"Azure",
		cpanel:"cPanel",
		phpmyadmin:"phpMyAdmin",
		confluence:"Confluence",
		jira:"Jira",
		tuleap:"Tuleap",
		redmine:"Redmine",
		jenkins:"Jenkins",
		swagger:"Swagger",
		windows:"Windows",
		macos:"macOS",
		ubuntu:"Ubuntu",
		centos:"CentOS",
		symfony:"Symfony",
		codeigniter:"CodeIgniter",
		thymeleaf:"Thymeleaf",
		joomla:"Joomla",
		spring:"Spring",
		springmvc:"Spring MVC",
		springboot:"Spring Boot",
		excel:"Excel",
		csv:"CSV",
		pdf:"PDF",
		json:"JSON",
		word:"Word",
		zend:"Zend",
		unittest:"unit test",
		developer:"Developer",
		frontend:"Front end",
		backend:"Back end",
		fullstack:"Fullstack",
		tagcommander:"TagCommander",
		scrum:"Scrum",
		mocha:"MochaJS",
		phpunit:"PHPUnit",
		junit :"JUnit",
		springboottest :"SpringBootTest",
		database :"Database",
	},
	fr:{
		reactjs:"React",
		java:"Java",
		php:"PHP",
		javascript:"JavaScript",
		expressjs:"ExpressJS",
		mysql:"MySQL",
		mongodb:"MongoDB",
		sql:"SQL",
		postgresql:"PostgreSQL",
		jquery:"jQuery",
		nodejs:"NodeJs",
		css3:"CSS3",
		css:"CSS",
		html5:"HTML5",
		html:"HTML",
		typescript:"TypeScript",
		jest:"Jest",
		bootstrap:"Bootstrap",
		fontawesome:"Font Awesome",
		visualstudio:"Visual Studio",
		eclipse:"Eclipse",
		notepad:"Notepad++",
		netbeans:"NetBeans",
		intellij:"IntelliJ",
		phpstorm:"PhpStorm",
		filezilla:"FileZilla",
		git:"Git",
		svn:"SVN",
		azure:"Azure",
		cpanel:"cPanel",
		phpmyadmin:"phpMyAdmin",
		confluence:"Confluence",
		jira:"Jira",
		tuleap:"Tuleap",
		redmine:"Redmine",
		jenkins:"Jenkins",
		swagger:"Swagger",
		windows:"Windows",
		macos:"macOS",
		ubuntu:"Ubuntu",
		centos:"CentOS",
		symfony:"Symfony",
		codeigniter:"CodeIgniter",
		thymeleaf:"Thymeleaf",
		joomla:"Joomla",
		spring:"Spring",
		springmvc:"Spring MVC",
		springboot:"Spring Boot",
		excel:"Excel",
		csv:"CSV",
		pdf:"PDF",
		json:"JSON",
		word:"Word",
		zend:"Zend",
		unittest:"Test unitaire",
		developer:"Développeur",
		frontend:"Front end",
		backend:"Back end",
		fullstack:"Fullstack",
		tagcommander:"TagCommander",
		scrum:"Scrum",
		mocha:"MochaJS",
		phpunit:"PHPUnit",
		junit :"JUnit",
		springboottest :"SpringBootTest",
		database :"Base de données",
	}	
};

export const TECH_IMG = {
	javascript: javascript,
	reactjs: reactjs,
	html: html,
	css: css,
	java: java,
	php: php,
	nodejs: nodejs,
	expressjs: expressjs,
	mysql: mysql,
	postgresql: postgresql,
	mongodb: mongodb,
	sql: sql
};

export const TECH_RATE = {
	javascript: 8,
	reactjs: 8,
	html: 9,
	css: 9,
	java: 8,
	php: 9,
	nodejs: 5,
	mysql: 9,
	postgresql: 7,
	mongodb: 7,
	sql: 8
};
export const TECH_YEAR = {
	javascript: -4,
	reactjs: -1,
	html: 7,
	css: 7,
	java: 1,
	php: 6,
	nodejs: -4,
	mysql: 4,
	postgresql: 0,
	mongodb: 0,
	sql: 3
};