export const init = {
    en: {
        title: "Skills",
        applied: "Applied Skills",
        summary: "Summary",
        year: "year",
    },
    fr: {
        title: "Compétences",
        applied: "Compétences utilisées",
        summary: "Résumé",
        year: "An",
    }
}
