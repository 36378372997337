import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {  faMagnifyingGlass, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Header.css';
//-----------------------------------   utils   -----------------------------------
import store from '../../../store/store';
import { Trtor } from '../../../utils/general/Translator';
import { GetStateUrlLocale, MailTo } from '../../../utils/general/General';
import { highlightStr } from '../../../utils/general/String';
import { withParamsRouter, GetLocale, rmvLocale, SetUrl } from '../../../utils/general/Url';
import { LoadSearchData } from '../../../utils/general/Obj';
import { ME } from '../../../file/Myself';
import { init as lang } from '../../../i18/i18Lang';
//----------------------------------- other -----------------------------------
import linkedin from '../../../assets/images/linkedin.png';
import logo from '../../../assets/images/logo.png';

class Header extends Component {
  	constructor(props) {
		super(props);
		this.state = {
			lang: GetLocale(),
			query:    '',
			search:   '',
			result:   [],
			showAuto:  false,
			showSel:  false,
			showMenu:  false,
			showLang:  false,
			listSel:  [
				{
					nm:  Trtor('head', 'menu.skills'),
					cap: Trtor('head', 'menu.upper.skills'),
					url: "skills",
					sel: true
				},
				{
					nm:  Trtor('head', 'menu.resume'),
					cap: Trtor('head', 'menu.upper.resume'),
					url: "resume",
					sel: false
				},
				{
					nm:  Trtor('head', 'menu.history'),
					cap: Trtor('head', 'menu.upper.history'),
					url: "history",
					sel: false
				}
			]
		}    
	}
	componentDidUpdate(prevProps, prevState){
		if(this.props.params && this.props.params.query && this.props.params.query !== this.state.query){			
			this.setState({query: this.props.params.query}, ()=> {
				this.setState({search: this.props.params.query}, () => {
					this.search();
				});
			})
		}
	}
	componentDidMount(){        		
		if(this.props.menu){
			this.menuSel(this.props.menu.charAt(0).toUpperCase() + this.props.menu.slice(1));
		}
        document.addEventListener('mousedown', this.handleClickOutside);
	}
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = (event) => {        
        if(this.state.showSel){
            let allowedDiv = ["hd-bd-src-tp", "hd-bd-src-tp", "hd-bd-src-tp-txt", 
			"hd-bd-src-tp-arr", "hd-bd-src-tp-auto", "hd-bd-src-sel-auto-elm"]
            if(event.target && event.target.className){
                if(!allowedDiv.includes(event.target.className)){
                    this.setState({showSel: false})
                }
            }else{
                this.setState({showSel: false})
            }
        }
		if(this.state.showAuto){
            let allowedDiv = ["hd-bd-src-txt-auto-elm", "hd-bd-src-txt-auto", "hd-bd-src-inp-tp", "hd-bd-src-inp"]
            if(event.target && event.target.className){
                if(!allowedDiv.includes(event.target.className)){
                    this.setState({showAuto: false})
					this.setState({result: []})
                }
            }else{
				if(event.target.closest('div') && event.target.closest('div').className){
					if(!allowedDiv.includes(event.target.closest('div').className)){
						this.setState({showAuto: false})
						this.setState({result: []})
					}
				}else{
					this.setState({showAuto: false})
					this.setState({result: []})
				}
			}
        }
		if(this.state.showLang){
            let allowedDiv = ["hd-tp-lg-flg", "hd-tp-lg-txt", "hd-tp-lg-mr-elm", 
			"hd-tp-lg-mr", "hd-tp-lg", "hd-tp-lg-kbx", "hd-tp-lg-flgimg"]
            if(event.target && event.target.className){
                if(!allowedDiv.includes(event.target.className)){
                    this.setState({showLang: false})
                }
            }else{
                this.setState({showLang: false})
            }
        }
		if(this.state.showMenu){
            let allowedDiv = ["hd-tp-lg-mn", "hd-tp-lg-bar", "hd-tp-bar", 
			"hd-tp-lg-mn-mr", "hd-tp-lg-mr-elm"]
            if(event.target && event.target.className){
                if(!allowedDiv.includes(event.target.className)){
                    this.setState({showMenu: false})
                }
            }else{
                this.setState({showMenu: false})
            }
        }
    } 
	menu(){
        if(!this.state.showSel){            
            this.setState({showSel: true})
        }
    }
	goTo(url){
		this.props.history(`${GetStateUrlLocale()}${url}`);
    }
	activeMenu(url){
		return this.props.menu === url  ? ` hd-tp-txt-on`: "";
    }
	searchGoto(query){
		let url = this.getMenuActiveUrl();
		if(query.length < 25){
			url = `/${url}/${query.toLowerCase()}`;
			this.setState({search: query});
		}else{
			url = `/${url}/${this.state.search.toLowerCase()}`;
		}
		this.setState({result: []}, () => {
			this.setState({showAuto: false});
			this.props.history(`${GetStateUrlLocale()}${url}`);
		})
    }
	searchIcon(){
		if(this.state.search){
			this.searchGoto(this.state.search.toLowerCase())
		}
	}
	search(){
		if(this.state.search){
			let ret = [];
			let tab = LoadSearchData(this.getMenuActive());
			tab.forEach((el, index) => {
				let search = highlightStr.updateString(el, this.state.search, '<b>');
				if(search.count > 0){
					ret.push(
						<div key={`auto-${index}`} className="hd-bd-src-txt-auto-elm" onClick={() => {this.searchGoto(search.origin)}}>
							<span dangerouslySetInnerHTML={{__html:search.str}} />
						</div>
					);
				}
			});
			if(ret.length !== this.state.length){
				this.setState({result: ret});
			}
		}
	}
	handleValue(event){
		this.setState({search: event.target.value}, () => {
			this.setState({showAuto: true}, () => {
				if(this.props.hasOwnProperty('query')){
					this.props.query(null, "header");
				}
			});
			this.search();
		});		
	}
	getMenuActive(){
		return this.state.listSel[this.state.listSel.findIndex(el => el.sel)].nm;
	}
	getMenuActiveUrl(){
		return this.state.listSel[this.state.listSel.findIndex(el => el.sel)].url;
	}
	menuSel(name){
		let index = this.state.listSel.findIndex(el => el.nm === name)
		let copy  = this.state.listSel.map(el => {
			return {
				...el,
				sel: false
			}
		});

		if(copy[index]){
			copy[index].sel = true; 
			this.setState({listSel: copy});
			this.setState({showSel: false});
		}
	}
	getLang(type){
		if(type === "displayed"){
			return (
				<div key={`lang-lg`} className='hd-tp-lg-kbx' onClick={() => {this.setState({showLang: this.state.showLang ? false : true})}}>
					<div className="hd-tp-lg-flg">
						<img src={Trtor('lang', 'icn')} style={{width:"20px"}} alt="english language"/>
					</div>
					<div className="hd-tp-lg-txt">
						{Trtor('lang', 'nm')}
					</div>
					<div className="hd-tp-lg-arr">
						<FontAwesomeIcon icon={faChevronDown} className='arr-mn'/>
					</div>
					<div className="clr"></div>
				</div>
			)
		}else{
			let data = this.state.lang === "en" ? lang.fr : lang.en; 
			return (
				<div key={`lang-lgs`} className={this.state.showLang ? `hd-tp-lg-mr` : `hd-tp-lg-mr dispnone`} onClick={() => {this.setLang(data.url)}}>
					<div className='hd-tp-lg-mr-elm'>
						<div className="hd-tp-lg-flg">
							<img src={data.icn} className='hd-tp-lg-flgimg' style={{width:"20px"}} alt={data.nm}/>
						</div>
						<div className="hd-tp-lg-txt" style={{paddingRight:"0"}}>
							{data.nm}
						</div>
						<div className="clr"></div>
					</div>
				</div>
			)
		}
	}
	setLang(locale){
		this.setState({showLang: false}, () => {
			this.setState({lang: locale}, () => {
				store.dispatch({ type: 'SET_LOCALE', payload: locale })			
				let url    = rmvLocale()
				let urlSet = SetUrl(url);
				urlSet     = urlSet.length > 0 ? urlSet : "/";
				this.props.history(urlSet);
			})
		})
	}
	getMenu(){
		let ret = [];
		ret = this.state.listSel.map((el, index) => {
			return (
				<React.Fragment key={`menusm-${index}`}>
					<div className='hd-tp-lg-mr-elm' onClick={() => this.goTo(`/${el.url}`)}>
						{el.nm}
					</div>
				</React.Fragment>
			)
		})
		ret.push(
			<React.Fragment key={`menusm-cnt`}>
				<div className='hd-tp-lg-mr-elm' onClick={() => MailTo(ME.email)}>
					{Trtor('head', 'menu.contact')}
				</div>
			</React.Fragment>
		);
		return ret;
	}
  	render(){
    	return (
      	<>
			<div className="hd-tp-mid">
				<div className="hd-tp">
					<div className="hd-tp-lft">
						<div className="hd-tp-lft hd-tp-icn">
							<Link to={`${ME[this.state.lang].linkedin}`} target='_blank'>
								<img src={linkedin} className="imgmax" alt="LinkedIn profile"/>
							</Link>
						</div>
						<div className="hd-tp-sep" style={{width:"12px",marginLeft:"10px"}}></div>
						<div className="hd-tp-lft hd-tp-on">
							<div className="hd-tp-lft hd-tp-sts">
								{Trtor('head', 'avail')}
							</div>
							<div className="clr"></div>
						</div>
						<div className="clr"></div>
					</div>
					<div className="hd-tp-rgt">
						<div className="ht-tp-rgt-lg">
							{
								this.state.listSel.map((el, index) => {
									return (
										<React.Fragment key={`menu-${index}`}>
											<div className={`hd-tp-txt${this.activeMenu(el.nm)}`} onClick={() => this.goTo(`/${el.url}`)}>
												{el.cap}
											</div>
											<div className="hd-tp-sep"></div>
										</React.Fragment>
									)
								})
							}						
							<div className={`hd-tp-txt${this.activeMenu('contact')}`}  onClick={() => MailTo(ME.email)}>
								{Trtor('head', 'menu.upper.contact')}
							</div>
							<div className="hd-tp-sep"></div>
							<div className="hd-tp-lg">
								{this.getLang("displayed")}
								{this.getLang("hidden")}
							</div>
							<div className="clr"></div>
						</div>
						<div className="ht-tp-rgt-sm">
							<div className='hd-tp-lg-mn'>
								<div className='hd-tp-lg-bar' onClick={() => {this.setState({showMenu: this.state.showMenu ? false : true})}}>
									<div className='hd-tp-bar'>&nbsp;</div>
									<div className='hd-tp-bar'>&nbsp;</div>
									<div className='hd-tp-bar'>&nbsp;</div>
								</div>
								<div className={this.state.showMenu ? `hd-tp-lg-mn-mr` : `hd-tp-lg-mn-mr dispnone`}>
									{this.getMenu()}
								</div>
							</div>
							<div className="hd-tp-lg hd-tp-lg-sm">
								{this.getLang("displayed")}
								{this.getLang("hidden")}
							</div>
							<div className="clr"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="clr"></div>
			<div className="hd-bd">
				<div className="hd-bd-mid">
				<div className="hd-bd-lg" onClick={() => this.goTo('/')}>
					<img src={logo} className="imgmax" alt="logo JL-Michael"/>
				</div>
				<div className="hd-bd-txt" onClick={() => this.goTo('/')}>
					JL-Michael
				</div>
				<div className="hd-bd-src">
					<div className="hd-bd-src-mid">
					<div className="hd-bd-src-txt">
						<div className="hd-bd-src-icn" onClick={() => {this.searchIcon()}}>
							<FontAwesomeIcon icon={faMagnifyingGlass} />
						</div>
						<div className="hd-bd-src-inp">
							<input type="text" value={this.state.search} placeholder={`${Trtor('head', 'src')}...`} name="q" className="hd-bd-src-inp-tp" 
								onChange={(e) => this.handleValue(e)}
								maxLength="35"
							/>								
						<div className={this.state.showAuto ? `hd-bd-src-txt-auto` : `hd-bd-src-txt-auto dispnone`}>
							{this.state.result}
						</div>
						</div>
						<div className="clr"></div>
					</div>
					<div className="hd-bd-src-tp" onClick={() => this.menu()}>
						<div className="hd-bd-src-tp-sep"></div>
						<div className="hd-bd-src-tp-txt">
							{this.getMenuActive()}
						</div>
						<div className="hd-bd-src-tp-arr">
							<FontAwesomeIcon icon={faChevronDown} className='arr-mn' />
						</div>
						<div className="clr"></div>								
						<div className={this.state.showSel ? `hd-bd-src-tp-auto` : `hd-bd-src-tp-auto dispnone`}>
							{
								this.state.listSel.map((el, index) => {
									if(!el.sel){
										return (
											<div key={`sel-${index}`} className="hd-bd-src-sel-auto-elm" onClick={() => this.menuSel(el.nm)}>
												{el.nm}
											</div>
										)
									}
									return null
								})
							}
						</div>
					</div>
					<div className="clr"></div>
					</div>
				</div>
				<div className="clr"></div>
				</div>
			</div>
      	</>
		);
	}
}
export default withParamsRouter(Header);
