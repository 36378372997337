import { GetStateLocale } from './General';
export const LoadI18 = function (name) {
    switch (name) {
        case "general":
            return require('../../i18/i18General');
        case "head":
            return require('../../i18/i18Head');
        case "foot":
            return require('../../i18/i18Foot');
        case "home":
            return require('../../i18/i18Home');
        case "resume":
            return require('../../i18/i18Resume');
        case "skills":
            return require('../../i18/i18Skills');
        case "history":
            return require('../../i18/i18History');  
        case "lang":
            return require('../../i18/i18Lang');  
        default:
            return {};
    }
}
export const Trans = function (i18, path) {
    if(i18 && i18.hasOwnProperty('init')){        
        let obj = i18.init
        path.split('.').forEach((elm, index) => {
            if(obj[elm]){				
              obj = obj[elm]
            }else{
                obj = 'undefined';
                obj = '...';
            }
        });
        return obj
    }
    return '...'
    //return 'undefined'
}
export const Trtor = function (nameI18, path) {
    return Trans(LoadI18(nameI18), `${GetStateLocale()}.${path}`)
}