import { EDUCATION } from './NameTech';
import { COMPANY } from './Company';
import { RESUME_DATA } from './ResumeData';
export const TIME_LINE = {
	en:[
		{
			role: EDUCATION.en.title,
			company: EDUCATION.en.school,
			timeStart: EDUCATION.en.timeStart,
			timeEnd: EDUCATION.en.timeEnd,
			desc: EDUCATION.en.desc,
			city: EDUCATION.en.state,
			state: EDUCATION.en.state,
			country: EDUCATION.en.country
		},
		{
			role: RESUME_DATA.en[7].role,
			company: COMPANY.en.freelance.name,
			timeStart: COMPANY.en.freelance.timeStart,
			timeEnd: COMPANY.en.freelance.timeEnd,
			desc: COMPANY.en.freelance.desc,
			city: COMPANY.en.freelance.city,
			country: COMPANY.en.freelance.country,
		},
		{
			role: RESUME_DATA.en[6].role,
			company: COMPANY.en.afml.name,
			timeStart: COMPANY.en.afml.timeStart,
			timeEnd: COMPANY.en.afml.timeEnd,
			desc: COMPANY.en.afml.desc,
			city: COMPANY.en.afml.city,
			country: COMPANY.en.afml.country,
		},
		{
			role: RESUME_DATA.en[5].role,
			company: COMPANY.en.orange.name,
			timeStart: COMPANY.en.orange.timeStart,
			timeEnd: COMPANY.en.orange.timeEnd,
			desc: COMPANY.en.orange.desc,
			city: COMPANY.en.orange.city,
			country: COMPANY.en.orange.country,
		},
		{
			role: RESUME_DATA.en[4].role,
			company: COMPANY.en.fdj.name,
			timeStart: COMPANY.en.fdj.timeStart,
			timeEnd: COMPANY.en.fdj.timeEnd,
			desc: COMPANY.en.fdj.desc,
			city: COMPANY.en.fdj.city,
			country: COMPANY.en.fdj.country,
		},
		{
			role: RESUME_DATA.en[3].role,
			company: COMPANY.en.frenchgovernment.name,
			timeStart: COMPANY.en.frenchgovernment.timeStart,
			timeEnd: COMPANY.en.frenchgovernment.timeEnd,
			desc: COMPANY.en.frenchgovernment.desc,
			city: COMPANY.en.frenchgovernment.city,
			country: COMPANY.en.frenchgovernment.country,
		},
		{
			role: RESUME_DATA.en[2].role,
			company: COMPANY.en.jpcolonna.name,
			timeStart: COMPANY.en.jpcolonna.timeStart,
			timeEnd: COMPANY.en.jpcolonna.timeEnd,
			desc: COMPANY.en.jpcolonna.desc,
			city: COMPANY.en.jpcolonna.city,
			country: COMPANY.en.jpcolonna.country,
		},
		{
			role: RESUME_DATA.en[1].role,
			company: COMPANY.en.desjardins.name,
			timeStart: COMPANY.en.desjardins.timeStart,
			timeEnd: COMPANY.en.desjardins.timeEnd,
			desc: COMPANY.en.desjardins.desc,
			city: COMPANY.en.desjardins.city,
			country: COMPANY.en.desjardins.country,
		}
	],
	fr:[
		{
			role: EDUCATION.fr.title,
			company: EDUCATION.fr.school,
			timeStart: EDUCATION.fr.timeStart,
			timeEnd: EDUCATION.fr.timeEnd,
			desc: EDUCATION.fr.desc,
			city: EDUCATION.fr.state,
			state: EDUCATION.fr.state,
			country: EDUCATION.fr.country
		},
		{
			role: RESUME_DATA.fr[7].role,
			company: COMPANY.fr.freelance.name,
			timeStart: COMPANY.fr.freelance.timeStart,
			timeEnd: COMPANY.fr.freelance.timeEnd,
			desc: COMPANY.fr.freelance.desc,
			city: COMPANY.fr.freelance.city,
			country: COMPANY.fr.freelance.country,
		},
		{
			role: RESUME_DATA.fr[6].role,
			company: COMPANY.fr.afml.name,
			timeStart: COMPANY.fr.afml.timeStart,
			timeEnd: COMPANY.fr.afml.timeEnd,
			desc: COMPANY.fr.afml.desc,
			city: COMPANY.fr.afml.city,
			country: COMPANY.fr.afml.country,
		},
		{
			role: RESUME_DATA.fr[5].role,
			company: COMPANY.fr.orange.name,
			timeStart: COMPANY.fr.orange.timeStart,
			timeEnd: COMPANY.fr.orange.timeEnd,
			desc: COMPANY.fr.orange.desc,
			city: COMPANY.fr.orange.city,
			country: COMPANY.fr.orange.country,
		},
		{
			role: RESUME_DATA.fr[4].role,
			company: COMPANY.fr.fdj.name,
			timeStart: COMPANY.fr.fdj.timeStart,
			timeEnd: COMPANY.fr.fdj.timeEnd,
			desc: COMPANY.fr.fdj.desc,
			city: COMPANY.fr.fdj.city,
			country: COMPANY.fr.fdj.country,
		},
		{
			role: RESUME_DATA.fr[3].role,
			company: COMPANY.fr.frenchgovernment.name,
			timeStart: COMPANY.fr.frenchgovernment.timeStart,
			timeEnd: COMPANY.fr.frenchgovernment.timeEnd,
			desc: COMPANY.fr.frenchgovernment.desc,
			city: COMPANY.fr.frenchgovernment.city,
			country: COMPANY.fr.frenchgovernment.country,
		},
		{
			role: RESUME_DATA.fr[2].role,
			company: COMPANY.fr.jpcolonna.name,
			timeStart: COMPANY.fr.jpcolonna.timeStart,
			timeEnd: COMPANY.fr.jpcolonna.timeEnd,
			desc: COMPANY.fr.jpcolonna.desc,
			city: COMPANY.fr.jpcolonna.city,
			country: COMPANY.fr.jpcolonna.country,
		},
		{
			role: RESUME_DATA.fr[1].role,
			company: COMPANY.fr.desjardins.name,
			timeStart: COMPANY.fr.desjardins.timeStart,
			timeEnd: COMPANY.fr.desjardins.timeEnd,
			desc: COMPANY.fr.desjardins.desc,
			city: COMPANY.fr.desjardins.city,
			country: COMPANY.fr.desjardins.country,
		}
	]
};