import { Route, Routes } from 'react-router-dom';
import { GetRouteUrl } from './utils/general/Url';
import { RunFirst } from './utils/runFirst';

import Home from './components/home/Home';
import Resume from './components/resume/Resume';
import Skills from './components/skills/Skills';
import History from './components/history/History';
//import Page404 from './components/page/page404/Page404';
/*not found*/
function App() {
	RunFirst(/*(msg) => console.log(msg)*/);
  	return (
		<Routes>
			<Route path={GetRouteUrl('home')}		element={<Home />} exact />
			<Route path={GetRouteUrl('resume')}		element={<Resume />} exact />
			<Route path={GetRouteUrl('resume-a')}	element={<Resume />} exact />
			<Route path={GetRouteUrl('skills')}		element={<Skills />} exact />
			<Route path={GetRouteUrl('skills-a')}	element={<Skills />} exact />
			<Route path={GetRouteUrl('history')}	element={<History />} exact />
			<Route path={GetRouteUrl('history-a')}	element={<History />} exact />
			<Route path={"/"}						element={<Home />} />
			<Route path={"*"}						element={<Home />} />
		</Routes>
  	);
}
export default App;
