import React, { Component } from 'react';
import { faCheckCircle, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Home.css';
//-----------------------------------   utils   -----------------------------------
import { Trtor } from '../../utils/general/Translator';
import { getYear } from '../../utils/general/String';
import { MailTo } from '../../utils/general/General';
import { HOME_SKILL } from '../../file/ResumeData';
import { ME } from '../../file/Myself';
import { GetLocale, withParamsRouter } from '../../utils/general/Url';
import { GetStateUrlLocale } from '../../utils/general/General';
import { TECH_NM, TECH_IMG, TECH_RATE } from '../../file/NameTech';
import { COMPANY } from '../../file/Company';
//----------------------------------- component -----------------------------------
import Header from '../body/header/Header';
import Footer from '../body/footer/Footer';

class Home extends Component {
  constructor(props) {
		super(props);
		this.state = {
			lang: GetLocale(),
			skill:{
				frontend:true,
				backend:false,
				database:false,
			},
			skillCard: HOME_SKILL.frontend,
			showCardSm: false,
		} 
	}
	componentDidMount(){        		
        document.addEventListener('mousedown', this.handleClickOutside);
	}
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = (event) => {        
        if(this.state.showCardSm){
            let allowedDiv = ["hm-sk-rgt-elm", "hm-sk-rgt-sm-mr", "hm-sk-rgt-elm", "hm-sk-rgt-sm", 
			"hm-sk-rgt-elm hm-sk-rgt-elm-first"]
            if(event.target && event.target.className){
                if(!allowedDiv.includes(event.target.className)){
                    this.setState({showCardSm: false})
					console.log("xxxx", event.target.className);
                }
            }else{
                this.setState({showCardSm: false})
				console.log("wwww");
            }
        }
	}
	boxSkill(key, val){
		if(this.state.skill.hasOwnProperty(key)){
			let obj = {};
			for (const property in this.state.skill) {
				obj[property] = false;
			}
			obj[key] = val;
			this.setState({skill: obj});
			if(this.state.showCardSm){
				this.setState({showCardSm: false});
			}
			this.setState({skillCard: HOME_SKILL[key]});
			
		}
	}
	boxHtmlSkill(type){
		let ret = [];
		let tab = TECH_NM[GetLocale()];
		if(type === "txt"){
			for (const property in this.state.skill) {
				if(this.state.skill[property]){
					ret.push(<>&nbsp;&nbsp;{tab[property]}</>);
				}
			}
		}else if(type === "full"){
			for (const property in this.state.skill) {
				ret.push(
					<div key={`hmskill-${property}`} className="hm-sk-rgt-elm" onClick={() => this.boxSkill(property, true)}>
						<FontAwesomeIcon icon={faCheckCircle} className={this.state.skill[property] ? 'hm-sk-on' : 'hm-sk-off'}  />
						&nbsp;&nbsp;{tab[property]}
					</div>
				);
			}
		}else{
			for (const property in this.state.skill) {
				if(!this.state.skill[property]){
					ret.push(
						<div key={`hmskill-${property}`} className="hm-sk-rgt-elm" onClick={() => this.boxSkill(property, true)}>
							<FontAwesomeIcon icon={faCheckCircle} className={'hm-sk-off'}  />
							&nbsp;&nbsp;{tab[property]}
						</div>
					);
				}
			}
		}
		return ret;
	}
	cardSkill(){
		let ret = [];
		this.state.skillCard.forEach(element => {
			let margin = ret.length === 0 ?  "" :  " mg-l20"
			ret.push(
				<div key={`crdskill-${ret.length}`} className={`hm-sk-lst-elm${margin}`}>
					<div className="hm-sk-lst-elm-hd">
						<div className="hm-sk-lst-elm-img">
							<img src={TECH_IMG[element]} className="imgmax hm-sk-img" alt={`${TECH_NM[this.state.lang][element]}`} />
						</div>
					</div>
					<div className="hm-sk-lst-elm-ft">							
						<div className="fltl">{TECH_NM[this.state.lang][element]}</div>
						<div className="fltr hm-sk-lst-elm-ft-rt">
							{TECH_RATE[element]} <FontAwesomeIcon icon={faStar} />
						</div>
						<div className="clr"></div>
					</div>
				</div>
			);
		});
		return ret;
	}
	cardCompany(){
		let ret = [];
		for (const property in COMPANY[this.state.lang]) {
			let margin = ret.length === 0 ?  "" :  " mg-r20"
			ret.unshift(
				<div key={`crdcomp-${property}`} className={`hm-comp-lst-elm${margin}`}>
					<div className="hm-comp-lst-elm-hd">
						<img src={COMPANY[this.state.lang][property].img} className="imgmax" alt={`company ${COMPANY[this.state.lang][property].name}`} />
					</div>
					<div className="hm-comp-lst-elm-ft">
						{getYear(COMPANY[this.state.lang][property].timeStart)} - {getYear(COMPANY[this.state.lang][property].timeEnd)}
					</div>
				</div>	
			);
		};
		return ret.slice(0,5);
	}
	goTo(url){
		this.props.history(`${GetStateUrlLocale()}${url}`);
    }
  	render(){
    	return (
      		<div className="hm-cont">
				<Header />
				<div className="hm-bn">
					<div className="hm-bn-mid">
						<h1 className="hm-bn-hd">
							{Trtor('home', 'ban.title')}
						</h1>
						<div className="hm-bn-subhd">
							{Trtor('home', 'ban.desc')} <span className='hm-bn-subhd-lg'>{Trtor('home', 'ban.desc_b')}</span>
						</div>
						<div className='hm-bn-bx-btn'>							
							<div className="hm-bn-dbtn">
								<button className="hm-bn-btn" onClick={() => this.goTo('/resume')}>
									{Trtor('home', 'ban.btn')}
								</button>
							</div>
						</div>
					</div>
					<div className="hm-bn-pol"></div>
				</div>
				<div className="hm-sk">
					<div className="main-bd">
						<div className="hm-sk-hd">
							<div className="hm-sk-rgt">
								<div className="hm-sk-rgt-lg">
									{this.boxHtmlSkill('full')}
									<div className="clr"></div>
								</div>
								<div className="hm-sk-rgt-sm">
									<div className="hm-sk-rgt-elm hm-sk-rgt-elm-first" style={{float:"unset"}} onClick={() => {
										console.log("click");
										this.setState({showCardSm: this.state.showCardSm ? false : true})}}>
										<FontAwesomeIcon icon={faCheckCircle} className={'hm-sk-on'}  />
										{this.boxHtmlSkill('txt')[0]}
									</div>
									<div className={this.state.showCardSm ? `hm-sk-rgt-sm-mr` : `hm-sk-rgt-sm-mr dispnone`}>
										{this.boxHtmlSkill('sm')}
										<div className="clr"></div>
									</div>
								</div>
							</div>
							<div className="clr"></div>
						</div>
						<div className="hm-sk-lst">
							{this.cardSkill()}
						</div>
					</div>
				</div>		
				<div className="hm-comp">
					<div className="main-bd">
						<div className="hm-comp-h">
							<h3>{Trtor('home', 'comp.title')}</h3>
						</div>
						<div className="hm-comp-lst">					
							{this.cardCompany()}
						</div>
					</div>
				</div>
				<div className="hm-sk">
					<div className="main-bd">
						<div className="hm-em">
							<div className="hm-em-bx">
								<h4 className="hm-em-tl">
									{Trtor('home', 'contact.title')}
								</h4>
								<div className="hm-em-subtl">
									{Trtor('home', 'contact.desc')}
									<br/>
									{Trtor('home', 'contact.desc_b')}
								</div>
								<div className="mg-t20">
									<button className="hm-em-btn" onClick={() => MailTo(ME.email)}>
										{Trtor('home', 'contact.btn')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
      		</div>
		);
	}
}
export default withParamsRouter(Home);
