import { TECH_NM } from './NameTech';
import { URL_WEB } from './Constant';
import { init } from '../i18/i18Home';
import { EDUCATION } from './NameTech.js';
export const fileNmTime = '2024';
export const ME = {
	en:{
		fnm: "Michael",
		lnm: "Jean-Louis",
		desc: `${init.en.ban.desc} ${init.en.ban.desc_b}`,
		title: `${TECH_NM.en.fullstack} ${TECH_NM.en.developer} ${TECH_NM.en.javascript} / ${TECH_NM.en.reactjs} / ${TECH_NM.en.php} `,
		email: "mih.jeanlouis@gmail.com",
		phone: "(418) 580 0438",
		site: URL_WEB,
		linkResumePdf: `${URL_WEB}/public/Michael-JEAN-LOUIS-resume-${fileNmTime}-en.pdf`,
		//linkedin: "https://www.linkedin.com/in/michael-jl/",
		linkedin: "https://www.linkedin.com/in/jean-lm/",
		location: "Montreal, Canada",
		language: [{nm: "French", rate: 6},{nm: "English", rate: 6}],
		education: {degree: "Associate degree in computer science", school: EDUCATION.en.school, location: `${EDUCATION.en.city}, ${EDUCATION.en.country}`},
		interest: ["Basketball", "Beach", "Workout"],
	},
	fr:{
		fnm: "Michael",
		lnm: "Jean-Louis",
		desc: `${init.fr.ban.desc} ${init.fr.ban.desc_b}`,
		title: `${TECH_NM.fr.developer} ${TECH_NM.fr.fullstack} ${TECH_NM.fr.javascript} / ${TECH_NM.fr.reactjs} / ${TECH_NM.fr.php} `,
		email: "mih.jeanlouis@gmail.com",
		phone: "(418) 580 0438",
		site: URL_WEB,
		linkResumePdf: `${URL_WEB}/public/Michael-JEAN-LOUIS-resume-${fileNmTime}-fr.pdf`,
		//linkedin: "https://www.linkedin.com/in/michael-jl/",
		linkedin: "https://www.linkedin.com/in/jean-lm/",
		location: "Montreal, Canada",
		language: [{nm: "Français", rate: 6},{nm: "Anglais", rate: 6}],
		education: {degree: "Diplôme en science informatique", school: EDUCATION.fr.school, location: `${EDUCATION.fr.city}, ${EDUCATION.fr.country}`},
		interest: ["Basket-ball", "Plage", "Séance de sport"],
	}
};