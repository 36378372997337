import './Footer.css';
//-----------------------------------   utils   -----------------------------------
import { Trtor } from '../../../utils/general/Translator';
const Footer =  () =>  {
	const currentYear = new Date().getFullYear();
	return (
		<>
		  <div className="ft-lg">
			  <div className="main-bd">
				  <div className="ft-bot">
					  <div className="ft-lft">{Trtor('foot', 'txt')} React</div>
					  <div className="ft-rgt">© {currentYear} JL-Michael</div>
					  <div className="clr"></div>
				  </div>
			  </div>
		  </div>
		</>
   );
}
export default Footer;
