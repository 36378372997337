import { GetLocale, SetHttpsUrl } from './general/Url';
import { useSelector, useDispatch } from "react-redux";

export const RunFirst = function (callBack) {
	SetHttpsUrl();
    const globalState = useSelector((state) => state); 
    const dispatch 	  = useDispatch();  
    const locale 	  = GetLocale()
    if(globalState.init === false){
        dispatch({ type: "SET_INIT", payload: true })
        dispatch({ type: "SET_LOCALE", payload: locale })
    }
    if(locale !== globalState.locale){
        dispatch({ type: "SET_LOCALE", payload: locale })
    }
    //const promise1 = Promise.resolve(123);

   // return callBack("1");
}
