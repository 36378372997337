import React, { Component } from 'react';
import { faDownload, faExternalLink, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Resume.css';
//-----------------------------------   utils   -----------------------------------
import { Trtor } from '../../utils/general/Translator';
import { getYear, highlightStr } from '../../utils/general/String';
import { RESUME_DATA, MAIN_SKILL_SUM } from '../../file/ResumeData';
import { withParamsRouter, GetLocale } from '../../utils/general/Url';
import { ME, fileNmTime } from '../../file/Myself';
//----------------------------------- component -----------------------------------
import Header from '../body/header/Header';
import Footer from '../body/footer/Footer';
import Highlight from '../form/highlight/Highlight';
import pdfResumeEn from '../../assets/file/Michael-JEAN-LOUIS-resume-2024-en.pdf';
import pdfResumeFr from '../../assets/file/Michael-JEAN-LOUIS-resume-2024-fr.pdf';

class Resume extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			query:"",
			filter:"",
			found: 0,
			summary: [],
			typing: "header",
			lang: GetLocale()
		}  
		this.sum    = [];
		this.found  = 0;
		this.nmPdf  = `${ME.en.fnm}-${ME.en.lnm}-resume.pdf`
		this.getExp = this.getExp.bind(this);
		this.pdfResumeEn = null;
		this.pdfResumeFR = null;
	}
	componentDidMount(){
		this.pdfResumeEn = async () => await import(`../../assets/file/Michael-JEAN-LOUIS-resume-${fileNmTime}-en.pdf`);		
		this.pdfResumeFr = async () => await import(`../../assets/file/Michael-JEAN-LOUIS-resume-${fileNmTime}-fr.pdf`);			
		if(this.props.params && this.props.params.query && this.props.params.query !== this.state.filter){		
			this.setState({filter: this.props.params.query}, ()=> {
				this.setState({query: this.props.params.query})
				this.getExp();
			})

		}
	}
	componentDidUpdate(){
		if(this.props.params && this.props.params.query && this.props.params.query !== this.state.query){
			if(this.state.typing === "header"){
				this.setState({query: this.props.params.query})
				this.setState({filter: this.props.params.query}, () => {
					this.getExp();
				})
			}
		}
	}
	setFound(nb){
		if(nb !== this.state.found){
			this.setState({found: nb})
		}
	}
	getName(){
		return (
			<h3 className="gen-sb-tl" style={{margin:"0"}}>
				{Trtor('resume', 'title')}
			</h3>
		);
	}
	getDesc(){
		return (
			<div className='rsum-desc'>{ME[this.state.lang].desc}</div>
		);
	}
	getTitle(){
		return (
			<div className='rsum-title mg-t8'>{ME[this.state.lang].title}</div>
		);
	}
	getExp(){
		let ret = [];
		let sum = [];
		let tech = [];
		let count = 0;
		const resumer = RESUME_DATA[this.state.lang];
		resumer.forEach((resume, ind) => {
			let margin  = ret.length === 0 ? " rsum-exp-top" : ""
			let time	= `${getYear(resume.timeStart)} - ${getYear(resume.timeEnd)}`;
			let role 	= highlightStr.updateString(resume.role, this.state.filter, '<span>');
			let company = highlightStr.updateString(resume.company, this.state.filter, '<span>');
			let timer   = highlightStr.updateString(time, this.state.filter, '<span>');
			count 		+= role.count;
			count 		+= company.count;
			count 		+= timer.count;
			if(resume.active.includes('any')){ 
				ret.push(
					<div className={`rsum-exp${margin}`} key={`rsum-exp-${ind}`}>
						<div className="rsum-bullet" style={{top: "4px"}}>
							<FontAwesomeIcon icon={faCircle} />	
						</div>
						<div className="rsum-role">
							<span dangerouslySetInnerHTML={{__html: role.str}} />				
						</div>
						<div className="rsum-company">
							<span dangerouslySetInnerHTML={{__html: company.str}} />							
						</div>
						<div className="clr"></div>
						<div className="rsum-time">
							<span dangerouslySetInnerHTML={{__html: timer.str}} />				
						</div>
						<div className="rsum-task">
							<ul className="rsum-list">
								{
								resume.task.map((task, index) => {
									let search = highlightStr.updateString(task, this.state.filter, '<span>');
									count += search.count;
									return (
										<li key={`task-${index}`}><span dangerouslySetInnerHTML={{__html: search.str}} /></li>
									)
								})
								}
							</ul>
						</div>
						<div className="rsum-tech">
							{
							resume.techSkill.map((skill, index) => {
								if(MAIN_SKILL_SUM.includes(skill) && tech.length < 3){
									tech.push(skill);
								}
								let search = highlightStr.updateString(skill, this.state.filter, '<span>');
								count += search.count;
								return (
									<div className="rsum-tag" key={`skill-${index}`}><span dangerouslySetInnerHTML={{__html: search.str}} /></div>
								)
							})
							}
						</div>
					</div>
				);
			}
			sum.push({
				role: resume.role,
				time: time,
				tech: tech
			});
			tech = [];
		});
		this.sum = sum;
		this.found = count;
		return ret;
	}
	getSummary(){
		let ret = [];
		this.sum.forEach((summary, ind) => {
			ret.push(
				<div className='rsum-sum-bx' key={`rsum-sum-${ind}`}>
					<div className="rsum-sum-lft">
						{summary.role}
					</div>
					<div className="rsum-sum-rgt">
						{summary.time}
					</div>
					<div className="clr"></div>
					<div className='rsum-sum-tech'>
						{
						summary.tech.map((tech, index) => {
							return (
								<div key={`tech-${index}`} className="rsum-sum-tag">{tech}</div>
							)
						})
						}
					</div>
				</div>
			);
		});
		return ret;
	}
	updateFilter(str, from){
		if(from === "header"){
			this.setState({typing: from});
		}else{
			this.setState({typing: from}, () => {
				this.setState({filter: str}, () => {
					this.getExp();
				});
			});
		}
	}
	linkPdf(){
		window.open(ME[this.state.lang].linkResumePdf);
	}
  	render(){		
    	return (
      		<div style={{background:"#fff"}}>
				<Header menu={Trtor('head', 'menu.resume')} query={(value, from) => this.updateFilter(value, from)} />
				<div className="gen-sb-hd">
					<div className='gen-mid'>
						{this.getName()}
					</div>
				</div>	
				<div className='gen-mid mg-t20 mg-b40'>
					<div className="rsum-lft">
						{this.getDesc()}
						{this.getTitle()}
						<div className='rsum-src-bx'>
							<Highlight count={this.found} param={this.state.query} uptQuery={(value, from) => this.updateFilter(value, from)} />
						</div>
						<div className='rsum-exp-nm'>
							{Trtor('resume', 'relevant')}:
						</div>
						{this.getExp()}
						<div className='rsum-bot-btn'>
							<div className="rsum-btn-lft">
								<a className="rsum-dwn-btn" href={this.state.lang === "en" ? pdfResumeEn : pdfResumeFr} download={this.nmPdf}>
									<FontAwesomeIcon icon={faDownload} />										
								</a>
							</div>
							<div className="rsum-btn-rgt">
								<button className="rsum-link-btn" onClick={() => this.linkPdf()}>
									<FontAwesomeIcon icon={faExternalLink} />	
								</button>
							</div>
							<div className="clr"></div>
						</div>
						<div className="clr"></div>					
					</div>
					<div className="rsum-rgt">
						<div className="rsum-ftr">
							<Highlight count={this.found} param={this.state.query} uptQuery={(value, from) => this.updateFilter(value, from)} />				
							<div className="rsum-txt">
								{Trtor('resume', 'pdf')}:
							</div>
							<div className="rsum-btn">
								<div className="rsum-btn-lft">
									<a className="rsum-dwn-btn" href={this.state.lang === "en" ? pdfResumeEn : pdfResumeFr} download={this.nmPdf}>
										<FontAwesomeIcon icon={faDownload} />										
									</a>
								</div>
								<div className="rsum-btn-rgt">
									<button className="rsum-link-btn" onClick={() => this.linkPdf()}>
										<FontAwesomeIcon icon={faExternalLink} />	
									</button>
								</div>
								<div className="clr"></div>
							</div>
						</div>
						<div className="rsum-sum">
							<span style={{fontWeight:"500"}}>{Trtor('resume', 'summary')}:</span>
							{this.getSummary()}									
						</div>
					</div>
					<div className="clr"></div>						
				</div>					
				<Footer />
      		</div>
		);
	}
}
export default withParamsRouter(Resume);
