export const init = {
    en: {
        menu: {
            resume:  'Resume',
            skills:  'Skills',
            history: 'Timeline',
            contact: 'Contact',
            upper:{
                resume:  'RESUME',
                skills:  'SKILLS',
                history: 'TIMELINE',
                contact: 'CONTACT',
            }
        },
        src:"Search",
		avail:"Available"
    },
    fr: {
        menu: {
            resume:  'CV',
            skills:  'Compétences',
            history: 'Chronologie',
            contact: 'Contact',
            upper:{
                resume:  'RÉSUMÉ',
                skills:  'COMPÉTENCES',
                history: 'CHRONOLOGY',
                contact: 'CONTACT',
            }
        },
        src:"Chercher",
		avail:"Disponible"
    }
}
