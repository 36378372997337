import { GetStateUrlLocale } from './General';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IsDev } from './Env';
import { URL_WEB } from '../../file/Constant';

export const IsHttps = () => {
	return GetFullUrl().substring(0, 6) === "https:"
}
export const GetFullUrl = () => {
	return window.location.href;
}
export const GetUrl = function () {
    return window.location.pathname
}
export const SetHttpsUrl = () => {
	if(!IsDev() && !IsHttps()){
		window.location.href = `${URL_WEB}${GetUrl()}`;
	}
}
export const GetLocale = function () {
    let tab = GetUrl().split('/');
    let locale = tab.find(
       (part, index) => index === 1 && ['fr'].includes(part)
    )
    locale = locale ? locale : 'en';
    return locale
}
export const GetRouteUrl = function (name) {
  const urlLocale = GetStateUrlLocale();
    switch (name) {
        case "home":
          return `${urlLocale}/`;
        case "resume":
            return `${urlLocale}/resume`;
        case "resume-a":
            return `${urlLocale}/resume/:query`;
        case "skills":
            return `${urlLocale}/skills`;
        case "skills-a":
            return `${urlLocale}/skills/:query`;
        case "history":
            return `${urlLocale}/history`;    
        case "history-a":
            return `${urlLocale}/history/:query`;                
        case "contact":
            return `${urlLocale}/contact`;           
        default:
            return `${urlLocale}/`;
  }
}
export const SetUrl = function (url) {
    return `${GetStateUrlLocale()}${url}`
}
export const andAddUrl = function (str, part) {
    let slash = part.length === 0 ? '' : '/';
    return str.length === 0 ? `${slash}${part}` : `${str}${slash}${part}`; 
}
export const rmvLocale = function () {
    let tab = GetUrl().split('/');
    let locale = '';
    tab.map((part, index) => {
        if(index === 1){
            if(!['fr'].includes(part)){
                locale = andAddUrl(locale, part)
            }
        }else if(part.length){
            locale = andAddUrl(locale, part)
        }
        return null
    })
    return locale
}
export const withLocation = Component => props => {
	const location = useLocation();
	return <Component {...props} location={location} />;
};
export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const history = useNavigate();
        return <Component history={history} {...props} />
    } 
    return Wrapper;
}
export const withParams = (Component) => {
    const Wrapper = (props) => {
        const params = useParams();
        return <Component params={params} {...props} />
    } 
    return Wrapper;
}
export const withParamsRouter = (Component) => {
    const Wrapper = (props) => {
        const params  = useParams();
		const history = useNavigate();
		const location = useLocation();
        return <Component 
			params={params} 
			history={history} 
			location={location}
			{...props} 
		/>
    } 
    return Wrapper;
}