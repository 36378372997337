import {RESUME_DATA, MAIN_SKILL} from '../../file/ResumeData';
import {TIME_LINE} from '../../file/TimeLine';
import { GetLocale } from '../../utils/general/Url';
import { init } from '../../i18/i18Head';

export const LoadSearchData = function(name) {
    let ret  = [];
    let lang = GetLocale();
    switch (name) {
        case init[lang].menu.skills:
            for(const property in MAIN_SKILL){                
                ret = ret.concat(MAIN_SKILL[property]);
            }
            return ret;
        case init[lang].menu.resume:
            RESUME_DATA[lang].forEach(resume => {
                for(const property in resume){
                    if(!['timeStart', 'timeEnd', 'city', 'country', 'desc'].includes(property)){
                        if(Array.isArray(resume[property])){
                            ret = ret.concat(resume[property]);
                        }else{
                            ret.push(resume[property]);
                        }
                    }
                }
                ret = [...new Set(ret)];
            });
            return ret;
        case init[lang].menu.history:
            TIME_LINE[lang].forEach(timeline => {
                for(const property in timeline){
                    ret = ret.concat(timeline[property]);
                }
            });
            ret = [...new Set(ret)];
            return ret;       
        default:
            return ret;
    }
}