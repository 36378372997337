import freelancer from '../assets/images/freelancer.png';
import afml from '../assets/images/afml.png';
import orange from '../assets/images/orange.png';
import fdj from '../assets/images/fdj.png';
import government from '../assets/images/government.png';
import colonna from '../assets/images/colonna.png';
import desjardins from '../assets/images/desjardins.png';

export const COMPANY = {
	en:{
		personal:{
			name: "Personal projects",
			timeStart: "Past",
			timeEnd: "Present",
		},
		freelance:{
			name: "Freelance",
			nm: "freelance",
			accron: "",
			img: freelancer,
			timeStart: "2009-01-01",
			timeEnd: "2011-12-01",
			company: "Freelance",
			field: "Freelance",
			city: "Les Abymes",
			country: "Guadeloupe ",
			desc: "Freelance work, mainly targeted start-ups that needed a website to showcase their businesses.",		
		},
		afml:{
			name: "Association Formation des Médecins Libéraux",
			nm: "association formation des médecins libéraux",
			accron: "AFML",
			img: afml,
			timeStart: "2011-02-01",
			timeEnd: "2013-01-01",
			company: "Association Formation des Médecins Libéraux",
			field: "E-learning",
			city: "Surenes",
			country: "France ",
			desc: "An application that offers online medical training session for professionals in the medical field.",		
		},
		orange:{
			name: "Orange",
			nm: "orange",
			accron: "",
			img: orange,
			timeStart: "2013-01-01",
			timeEnd: "2014-01-01",
			company: "Orange",
			field: "Telecommunication",
			city: "Guyancourt",
			country: "France ",
			desc: "Intranet application for managing physical and virtual servers for IT administrators.",		
		},
		fdj:{
			name: "Française des jeux",
			nm: "française des jeux",
			accron: "FDJ",
			img: fdj,
			timeStart: "2014-01-01",
			timeEnd: "2016-06-01",
			company: "La Française des Jeux",
			field: "Entertainemnt",
			city: "Moussy le vieux",
			country: "France ",
			desc: "Created new functionalities that allow webmasters to add new content to the website. Find solutions to make data load faster. Maintain and create new web services.",		
		},
		frenchgovernment:{
			name: "Ministère de la Justice et des Libertés",
			nm: "ministère de la justice et des libertés",
			accron: "",
			img: government,
			timeStart: "2016-08-01",
			timeEnd: "2017-07-01",
			company: "French Government",
			field: "Government",
			city: "Paris",
			country: "France ",
			desc: "Web App. that manages files and cases for the French Bureau of Nationality. Keep records of the acceptance/denial of the delivery of the Certificate of French Nationality.",		
		},
		jpcolonna:{
			name: "JP COLONNA",
			nm: "JP COLONNA",
			accron: "",
			img: colonna,
			timeStart: "2017-07-01",
			timeEnd: "2019-10-01",
			company: "JP COLONNA",
			field: "Insurance broker",
			city: "Paris",
			country: "France ",
			desc: "A web application that manages the e-reputation of companies from three main sources: Google, TripAdvisor and Booking.",
		},
		desjardins:{
			name: "DesJardins",
			nm: "DesJardins",
			accron: "",
			img: desjardins,
			timeStart: "2019-11-01",
			timeEnd: "Present",
			company: "DesJardins",
			field: "Insurance",
			city: "Montreal",
			country: "Canada ",
			desc: "Web application that provides insurance for homes and vehicles.",
		}
	},
	fr:{
		personal:{
			name: "Projets personnels",
			timeStart: "Passé",
			timeEnd: "Present",
		},
		freelance:{
			name: "Freelance",
			nm: "freelance",
			accron: "",
			img: freelancer,
			timeStart: "2009-01-01",
			timeEnd: "2011-12-01",
			company: "Freelance",
			field: "Freelance",
			city: "Les Abymes",
			country: "Guadeloupe ",
			desc: "Travail indépendant, ciblant principalement les start-up qui avaient besoin d'un site Web pour présenter leurs activités.",		
		},
		afml:{
			name: "Association Formation des Médecins Libéraux",
			nm: "association formation des médecins libéraux",
			accron: "AFML",
			img: afml,
			timeStart: "2011-02-01",
			timeEnd: "2013-01-01",
			company: "Association Formation des Médecins Libéraux",
			field: "E-learning",
			city: "Surenes",
			country: "France ",
			desc: "Une application qui propose des sessions de formation médicale en ligne pour les professionnels du domaine médical.",		
		},
		orange:{
			name: "Orange",
			nm: "orange",
			accron: "",
			img: orange,
			timeStart: "2013-01-01",
			timeEnd: "2014-01-01",
			company: "Orange",
			field: "Telecommunication",
			city: "Guyancourt",
			country: "France ",
			desc: "Application intranet de gestion des serveurs physiques et virtuels pour les administrateurs informatiques.",		
		},
		fdj:{
			name: "Française des jeux",
			nm: "française des jeux",
			accron: "FDJ",
			img: fdj,
			timeStart: "2014-01-01",
			timeEnd: "2016-06-01",
			company: "La Française des Jeux",
			field: "Divertissement",
			city: "Moussy le vieux",
			country: "France ",
			desc: "Création de nouvelles fonctionnalités permettant aux webmasters d'ajouter du nouveau contenu sur le site. Trouvez des solutions pour accélérer le chargement des données. Maintenir et créer de nouveaux services Web.",		
		},
		frenchgovernment:{
			name: "Ministère de la Justice et des Libertés",
			nm: "ministère de la justice et des libertés",
			accron: "",
			img: government,
			timeStart: "2016-08-01",
			timeEnd: "2017-07-01",
			company: "Gouvernement français",
			field: "Gouvernement",
			city: "Paris",
			country: "France ",
			desc: "Application Web. qui gère les dossiers et dossiers du Bureau français de la nationalité. Conserver les enregistrements de l'acceptation/refus de la délivrance du Certificat de Nationalité Française.",		
		},
		jpcolonna:{
			name: "JP COLONNA",
			nm: "JP COLONNA",
			accron: "",
			img: colonna,
			timeStart: "2017-07-01",
			timeEnd: "2019-10-01",
			company: "JP COLONNA",
			field: "Courtier d'assurance",
			city: "Paris",
			country: "France ",
			desc: "Une application web qui gère la e-réputation des entreprises à partir de trois sources principales : Google, TripAdvisor et Booking.",
		},
		desjardins:{
			name: "DesJardins",
			nm: "DesJardins",
			accron: "",
			img: desjardins,
			timeStart: "2019-11-01",
			timeEnd: "Present",
			company: "DesJardins",
			field: "Assurance",
			city: "Montreal",
			country: "Canada ",
			desc: "Application Web qui fournit des assurances pour les maisons et les véhicules.",
		}
	}
	
};