import flagfr from '../assets/images/fr-flag-sm.png';
import flagen from '../assets/images/en-flag-sm.png';
export const init = {
    en: {
        nm: "EN",
        url: "en",
        icn: flagen,
    },
    fr: {
        nm: "FR",
        url: "fr",
        icn: flagfr,
    }
}
