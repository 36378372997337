export const init = {
    en: {
        ban: {
            title: 'Fullstack developer',
            desc: 'Over 10 years of experiences working from large web applications to innovative ones.',
            desc_b: 'Passionate and focused on developing and create innovative products for the web.',
            btn: 'View my resume',        
        },
        skill: {
            db: 'Database',       
        },
        comp: {
            title: 'Great companies I\'ve worked for',       
        },
        contact: {
            title: 'Interested in hiring me for your project',  
            desc: 'Looking for an experienced fullstack developer to build your web app?',
            desc_b: 'Just drop me an email and I\'ll get back to you as soon as possible.',
            btn: 'Let\'s talk',     
        }
    },
    fr: {
        ban: {
            title: 'Développeur Fullstack',
            desc: 'Plus de 10 ans d\'expérience de travail, des grandes applications Web aux applications innovantes.',
            desc_b: 'Passionné et concentré sur le développement et la création de produits innovants pour le web.',
            btn: 'Voir mon CV',        
        },
        skill: {
            db: 'Base de données',       
        },
        comp: {
            title: 'Grandes entreprises pour lesquelles j\'ai travaillé',       
        },
        contact: {
            title: 'Intéressé à me recruter pour votre projet',  
            desc: 'Vous recherchez un développeur fullstack expérimenté pour créer votre application Web?',
            desc_b: 'Envoyez-moi simplement un e-mail et je vous répondrai dans les plus brefs délais.',
            btn: 'Parlons',     
        }
    }
}
