import React, { Component } from 'react';
import { faSuitcase, faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './History.css';
//-----------------------------------   utils   -----------------------------------
import { Trtor } from '../../utils/general/Translator';
import { getYear, highlightStr } from '../../utils/general/String';
import { TIME_LINE } from '../../file/TimeLine';
import { withParamsRouter, GetLocale } from '../../utils/general/Url';
//----------------------------------- component -----------------------------------
import Header from '../body/header/Header';
import Footer from '../body/footer/Footer';
import Highlight from '../form/highlight/Highlight';

class History extends Component {
  constructor(props) {
		super(props);
		this.state = {
			query:"",
			filter:"",
			found: 0,
			typing: "header",
			lang: GetLocale()
		}  
	}
	componentDidMount(){
		if(this.props.params && this.props.params.query && this.props.params.query !== this.state.filter){		
			this.setState({filter: this.props.params.query}, ()=> {
				this.setState({query: this.props.params.query})
				this.getHisto();
			})

		}
	}
	componentDidUpdate(){
		if(this.props.params && this.props.params.query && this.props.params.query !== this.state.query){
			if(this.state.typing === "header"){
				this.setState({query: this.props.params.query})
				this.setState({filter: this.props.params.query}, () => {
					this.getHisto();
				})
			}
		}
	}
	getHisto(){
		let ret 	= [];
		let count 	= 0;
		const timeline = TIME_LINE[this.state.lang];
		timeline.forEach((histo, ind) => {
			let even 	= ind % 2 === 0;
			let icn 	= ind > 0 ? faSuitcase : faGraduationCap;
			let time	= `${getYear(histo.timeStart)} - ${getYear(histo.timeEnd)}`;
			let role 	= highlightStr.updateString(histo.role, this.state.filter, '<span>');
			let company = highlightStr.updateString(histo.company, this.state.filter, '<span>');
			let desc    = highlightStr.updateString(histo.desc, this.state.filter, '<span>');
			let place   = highlightStr.updateString(`${histo.city}, ${histo.country}`, this.state.filter, '<span>');
			let timer   = highlightStr.updateString(time, this.state.filter, '<span>');
			count 		+= role.count;
			count 		+= company.count;
			count 		+= desc.count;
			count 		+= timer.count;
			count 		+= place.count;

			if(even){
				let clss = ind > 0 ? "hist-lft" : "hist-lft hist-lft-first";
				let tmc =  ind > 0 ? "hist-tm"  : "hist-tm hist-tm-first";
				let iss  = ind > 0 ? "hist-dot" : "hist-dot hist-dot-first";
				ret.push(
					<React.Fragment key={`tml-${ind}`}>
						<div className={clss}>
							<div className='hist-bx'>
								<div className='hist-arr'></div>
								<div className='hist-dot-sm'>
									<div className='hist-dot-bx-sm'>
										<FontAwesomeIcon icon={icn} />
										<div className='hist-tm-sm'>
											<span dangerouslySetInnerHTML={{__html: timer.str}} />
										</div>
									</div>
								</div>
								<div className='hist-txt'>
									<div className='hist-txt-tl'>
										<span dangerouslySetInnerHTML={{__html: role.str}} />
									</div>	
									<div className='hist-txt-subtl'>
										<span dangerouslySetInnerHTML={{__html: company.str}} />
									</div>	
									<div className='hist-txt-dsc'>
										<span dangerouslySetInnerHTML={{__html: desc.str}} />
									</div>
									<div className='hist-txt-loc'>
										<span dangerouslySetInnerHTML={{__html: place.str}} />
									</div>	
								</div>		
							</div>
						</div>
						<div className='hist-mid'>
							<div className={iss}>
								<FontAwesomeIcon icon={icn} />
							</div>
						</div>
						<div className='hist-rgt'>
							<div className='hist-bx hist-hide'>
								<div className={tmc}>
									<span dangerouslySetInnerHTML={{__html: timer.str}} />
								</div>
							</div>
						</div>
					</React.Fragment>
				);
			}else{				
				ret.push(
					<React.Fragment key={`tml-${ind}`}>
						<div className='hist-lft'>
							<div className='hist-bx hist-hide'>
								<div className='hist-tm txt-rgt'>
									<span dangerouslySetInnerHTML={{__html: timer.str}} />
								</div>
							</div>
						</div>
						<div className='hist-mid'>
							<div className='hist-dot'>
								<FontAwesomeIcon icon={icn} />
							</div>
						</div>
						<div className='hist-rgt'>
							<div className='hist-bx'>
								<div className='hist-arr hist-arrr'></div>
								<div className='hist-dot-sm'>
									<div className='hist-dot-bx-sm'>
										<FontAwesomeIcon icon={icn} />
										<div className='hist-tm-sm hist-tmr-sm'>
											<span dangerouslySetInnerHTML={{__html: timer.str}} />
										</div>
									</div>
								</div>
								<div className='hist-txt'>
									<div className='hist-txt-tl'>
										<span dangerouslySetInnerHTML={{__html: role.str}} />
									</div>	
									<div className='hist-txt-subtl'>
										<span dangerouslySetInnerHTML={{__html: company.str}} />
									</div>	
									<div className='hist-txt-dsc'>
										<span dangerouslySetInnerHTML={{__html: desc.str}} />
									</div>	
									<div className='hist-txt-loc hist-txt-locl'>
										<span dangerouslySetInnerHTML={{__html: place.str}} />
									</div>	
								</div>	
							</div>
						</div>
					</React.Fragment>
				);
			}
		});
		
		if(count !== this.state.found){
			this.setState({found: count})
		}
		return ret;
	}
	updateFilter(str, from){
		if(from === "header"){
			this.setState({typing: from});
		}else{
			this.setState({typing: from}, () => {
				this.setState({filter: str}, () => {
					this.getHisto();
				});
			});
		}
	}
  	render(){
    	return (
			<>
				<Header menu={Trtor('head', 'menu.history')} query={(value, from) => this.updateFilter(value, from)} />
				<div className="gen-sb-hd">
					<div className='gen-mid'>
						<h3 className="gen-sb-tl" style={{margin:"0"}}>
							{Trtor('history', 'title')}
						</h3>
					</div>
				</div>	
				<div className='gen-mid mg-t20 mg-b40'>
					<div className="rsum-src-bx hist-shadow" style={{display:"block"}}>
						<Highlight count={this.state.found} param={this.state.query} uptQuery={(value, from) => this.updateFilter(value, from)} />
					</div>	
					<div className='hist-cont'>	
						{this.getHisto()}									
					</div>
				</div>
				<Footer />
      		</>
		);
	}
}
export default withParamsRouter(History);
