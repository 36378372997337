export const getYear = (string) => {
    let year = string.substring(0,4);
    year = parseInt(year);
    if(isNaN(year)){
        return string;
    }
    return year;
}
export const findKeySkill = (skillName, skillList, skillListKey) => {
    let res = "na";
    for(const property in skillList){
        if(skillList[property] === skillName && skillListKey.hasOwnProperty(property)){
            res = property;
        }
    }
    return res;
}
export const highlightStr = (function(){
    let tagStart = `<span class="rsum-badge">`;
    let tagEnd   = `</span>`;
    let add      = tagStart.length+tagEnd.length;
    let indices  = [];
    let inc      = 0;
    let reset = () => {
        indices  = [];
        inc      = 0;
    }

    let setTag = (tag) => {
        if(tag === '<b>'){
            tagStart = `<b>`;
            tagEnd   = `</b>`;
        }else{
            tagStart = `<span class="rsum-badge">`;
            tagEnd   = `</span>`;
        }
    }
	let findIndex = (str, replacer) => {
        let result;
        //let clean = String(replacer).replace(/\W /g, '')
        let clean = String(replacer).replace(/[^a-zA-Z0-9\s]/g, '');
        let regex = new RegExp(clean, "gi");//let regex = /le/gi;
        while((result = regex.exec(str))){
            if(indices.length < 1){
                indices.push(result.index);
            }else{
                inc += add;
                indices.push(result.index + inc);
            }
        }
    }
    let replaceAt = (str, index, replacer) => {
        let realStr = str.substring(index, (index+replacer.length));
        return str.substring(0, index) + `${tagStart}${realStr}${tagEnd}` + str.substring(index + replacer.length);
    }
    let updateString = (str, replacer, tag) => {
        reset();
        let string = str;
        if(!replacer || replacer.length < 2){ return {count: indices.length, str: string}; }
        setTag(tag);
        findIndex(string, replacer);
        indices.forEach(elm => {
            string = replaceAt(string, elm, replacer);
        });
        return {count: indices.length, str: string, origin: str}; 
    }
	return {
		updateString: updateString,
	};
})();