import React, { Component } from 'react';
import './Highlight.css';
//-----------------------------------   utils   -----------------------------------
import { Trtor } from '../../../utils/general/Translator';
/*
countTime(){
	clearTimeout(typingTimer);
	typingTimer = setTimeout(this.doneTyping, doneTypingInterval);
}
doneTyping(){
	console.log("ssssssssss");
}
let typingTimer;
let doneTypingInterval = 5000;
*/
class Highlight extends Component {
  constructor(props) {
		super(props);
		this.state = {
			query: "",
			show: "",
			placeholder: `${Trtor('head', 'src')}...`,
			isTyping: false,
		}
	}
	componentDidMount(){
		this.handshowIcon();
	}
	componentDidUpdate(prevProps, prevState){
		if(this.props.param !== prevProps.param){
			if(this.props.param !== this.state.placeholder){
				this.setState({placeholder: this.props.param});	
			}
			this.setState({query: ""});	
			this.handshowIcon();
		}
	}
	handshowIcon(){		
		if(this.state.query.length > 1 || (this.state.placeholder && this.state.placeholder !== `${Trtor('head', 'src')}...`)){
			this.setState({show: " hlit-ftr-badge-on"});
		}else if(this.state.show.length > 0){
			this.setState({show: ""});
		}		
	}
	handleValue(event){
		this.setState({isTyping: true});
		this.setState({query: event.target.value});
		this.setState({query: event.target.value}, () => {
			this.props.uptQuery(this.state.query, "highlight");
			this.handshowIcon();	
		});
		this.setState({placeholder: `${Trtor('head', 'src')}...`});			
	}
  	render(){	
    	return (				
			<div className="hlit-ftr-inp">
				<div className={`hlit-ftr-badge${this.state.show}`}>
					{this.props.count}
				</div>
				<input type="text" placeholder={`${this.state.placeholder}`} name="q" className="hlit-inp" 
					value={this.state.query}
					maxLength={20}
					onChange={(e) => this.handleValue(e)}
					/*onKeyUp={this.countTime()}*/
				/>	
			</div>
		);
	}
}
export default Highlight;
