export const init = {
    en: {
        title: "Resume",
        relevant: "Relevant Experience",
        summary: "Summary",
        pdf: "PDF version",
    },
    fr: {
        title: "CV",
        relevant: "Expérience pertinente",
        summary: "Résumé",
        pdf: "Version PDF",
    }
}
