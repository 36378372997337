import React, { Component } from 'react';
import { faCircle, faCircleDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Skills.css';
//-----------------------------------   utils   -----------------------------------
import { Trtor } from '../../utils/general/Translator';
import { getYear, highlightStr,findKeySkill } from '../../utils/general/String';
import { RESUME_DATA, MAIN_SKILL } from '../../file/ResumeData';
import { TECH_NM, TECH_RATE, TECH_YEAR, TECH_LIST, TECH } from '../../file/NameTech';
import { withParamsRouter, GetLocale } from '../../utils/general/Url';
import { ME } from '../../file/Myself';
//----------------------------------- component -----------------------------------
import Header from '../body/header/Header';
import Footer from '../body/footer/Footer';
import Highlight from '../form/highlight/Highlight';

class Skills extends Component {	
  	constructor(props) {
		super(props);
		this.state = {
			query:"",
			filter:"",
			found: 0,
			typing: "header",
			summary: [],
			lang: GetLocale()
		}  
		this.getExp = this.getExp.bind(this);
		this.sum = [];
	}
	componentDidMount(){
		if(this.props.params && this.props.params.query && this.props.params.query !== this.state.filter){		
			this.setState({filter: this.props.params.query}, ()=> {
				this.setState({query: this.props.params.query})
				this.getExp();
			})

		}
	}
	componentDidUpdate(){
		if(this.props.params && this.props.params.query && this.props.params.query !== this.state.query){
			if(this.state.typing === "header"){
				this.setState({query: this.props.params.query})
				this.setState({filter: this.props.params.query}, () => {
					this.getExp();
				})
			}
		}
	}
	setFound(nb){
		if(nb !== this.state.found){
			this.setState({found: nb})
		}
	}
	getName(){
		return (
			<h3 className="gen-sb-tl" style={{margin:"0"}}>
				{Trtor('skills', 'title')}
			</h3>
		);
	}
	getTitle(){
		return (
			<div className='rsum-title'>
				{ME[this.state.lang].title}
			</div>
		);
	}
	
	getSkillCat(resume, property){
		let saveCatSkill = [];
		let newProp = [];				
		resume.techEnv.map((tech, indexTech) => {					
			if(MAIN_SKILL.hasOwnProperty(property) && MAIN_SKILL[property].includes(tech)){
				if(!newProp.includes(property)){
					newProp.push(property);
					saveCatSkill[property] = [];
				}
				saveCatSkill[property].push(tech);
			}
			return null
		});
		resume.techSkill.map((skill, indexTech) => {					
			if(MAIN_SKILL.hasOwnProperty(property) && MAIN_SKILL[property].includes(skill)){
				if(!newProp.includes(property)){
					newProp.push(property);
					saveCatSkill[property] = [];
				}
				saveCatSkill[property].push(skill);
			}
			return null
		});
		return saveCatSkill;
	}
	getDateElapsed(start, end){
		let starter = isNaN(start.substr(0, 4)) ? 0 : parseInt(start.substr(0, 4));
		let ender   = isNaN(end.substr(0, 4)) ? 0   : parseInt(end.substr(0, 4));
		if(end === "Present" && starter !== 0){
			ender = new Date().getFullYear();
		}
		return ender - starter;
	}
	getExp(){
		let ret = [];
		let sum = [];
		let count = 0;
		let expSkill = {};
		const resumer = RESUME_DATA[this.state.lang];
		resumer.forEach((resume, ind) => {
			let margin  = ret.length === 0 ? " rsum-exp-top" : ""

			let elapsed = this.getDateElapsed(resume.timeStart, resume.timeEnd);
			let time	= `${getYear(resume.timeStart)} - ${getYear(resume.timeEnd)}`;
			let role 	= highlightStr.updateString(resume.role, this.state.filter, '<span>');
			let timer   = highlightStr.updateString(time, this.state.filter, '<span>');
			let company = highlightStr.updateString(resume.company, this.state.filter, '<span>');
			count 		+= role.count;
			count 		+= company.count;
			count 		+= timer.count;

			let saveCatSkill = [];
			let newProp = [];				
			resume.techEnv.map((tech, indexTech) => {					
				for(const property in MAIN_SKILL){
					if(MAIN_SKILL[property].includes(tech)){
						if(!newProp.includes(property)){
							newProp.push(property);
							saveCatSkill[property] = [];
						}
						saveCatSkill[property].push(tech);
					}
				}
				return null
			});
			resume.techSkill.map((skill, indexTech) => {					
				for(const property in MAIN_SKILL){
					if(MAIN_SKILL[property].includes(skill)){
						if(!newProp.includes(property)){
							newProp.push(property);
							saveCatSkill[property] = [];
						}
						saveCatSkill[property].push(skill);
					}
				}
				return null
			});

			let catTech = [];
			for(const property in TECH_LIST[this.state.lang]){
				catTech.push(property);
			}

			let saveSkill  = {};
			let countSkill = 0;
			catTech.map((property, indexProp) => {
				let techSkill = this.getSkillCat(resume, property);
				if(techSkill.hasOwnProperty(property)){
					countSkill = countSkill + 1;
					saveSkill[`${property}`] = techSkill[property] ;
				}
				return null
			});
			let half = Math.floor(countSkill / 2);
			let even = countSkill % 2 === 0 ? true : false;
			let copyIndex = 0, countIndex = 0;
			ret.push(
				<div className={`rsum-exp${margin}`} key={`rsum-exp-${ind}`}>
					<div className="rsum-bullet" style={{top: "4px"}}>
						<FontAwesomeIcon icon={faCircle} />	
					</div>
					<div className="rsum-role">
						<span dangerouslySetInnerHTML={{__html: role.str}} />				
					</div>
					<div className="rsum-company">
					<div style={{textAlign:"right"}}><span dangerouslySetInnerHTML={{__html: company.str}} /></div>	
						<div style={{textAlign:"right", color:"#aeaeae"}}><span dangerouslySetInnerHTML={{__html: timer.str}} /></div>					
					</div>
					<div className="clr"></div>
					<div className="skl-div-lft">
					{						
						catTech.map((property, indexProp) => {
							if((even && countIndex < half) || (!even && countIndex <= half)){
								copyIndex = indexProp;
								if(saveSkill.hasOwnProperty(property)){
									countIndex++;
									let techNm = TECH_LIST[this.state.lang][property];
									let search = highlightStr.updateString(techNm, this.state.filter, '<span>');
									count += search.count;
									return (
										<div className="rsum-task" key={`skilltask-${property}-${indexProp}`}>
											<div className='rsum-sub-lab'>
												<span dangerouslySetInnerHTML={{__html: search.str}} />
											</div>
											<div className="rsum-list">
												
											{saveSkill[property].map((skill, indexSkill) => {
												let skillProp = findKeySkill(skill, TECH_NM[this.state.lang], TECH)
												if(expSkill.hasOwnProperty(skillProp)){
													expSkill[`${skillProp}`] += elapsed;
												}else{
													expSkill[`${skillProp}`] = elapsed;
												}
												let search = highlightStr.updateString(skill, this.state.filter, '<span>');
												count += search.count;
												return (
													<div key={`taskskill-${indexSkill}`} className="rsum-tag">
														<span dangerouslySetInnerHTML={{__html: search.str}} />
													</div>
												)
											})
											}
											</div>
										</div>
									)
								}
							}
							return null
						})
					}
					</div>
					<div className="skl-div-rgt">
					{						
						catTech.map((property, indexProp) => {
							if(indexProp > copyIndex){
								if(saveSkill.hasOwnProperty(property)){
									let techNm = TECH_LIST[this.state.lang][property];
									let search = highlightStr.updateString(techNm, this.state.filter, '<span>');
									count += search.count;
									return (
										<div className="rsum-task" key={`skilltask-${property}-${indexProp}`}>
											<div className='rsum-sub-lab'>
												<span dangerouslySetInnerHTML={{__html: search.str}} />
											</div>
											<div className="rsum-list" style={{marginTop:"4px"}}>
												
											{saveSkill[property].map((skill, indexSkill) => {
												let skillProp = findKeySkill(skill, TECH_NM[this.state.lang], TECH)
												if(expSkill.hasOwnProperty(skillProp)){
													expSkill[`${skillProp}`] += elapsed;
												}else{
													expSkill[`${skillProp}`] = elapsed;
												}
												let search = highlightStr.updateString(skill, this.state.filter, '<span>');
												count += search.count;
												return (
													<div key={`taskskill-${indexSkill}`} className="rsum-tag">
														<span dangerouslySetInnerHTML={{__html: search.str}} />
													</div>
												)
											})
											}
											</div>
										</div>
									)
								}
							}
							return null
						})
					}
					</div>
					<div className="clr"></div>									
				</div>
			);
		});
		for(const property in TECH_RATE){
			if(TECH_NM[this.state.lang].hasOwnProperty(property)){
				let year = 0;
				let rate = TECH_RATE[property];
				if(expSkill.hasOwnProperty(property)){
					year += expSkill[`${property}`];
				}
				if(TECH_YEAR.hasOwnProperty(property)){
					year += TECH_YEAR[`${property}`];
				}
				sum.push({
					role: TECH_NM[this.state.lang][property],
					tech: (<span> 
						{[0,1,2,3,4,5].map((elm, index) => {
								let icn = faCircle;
								let clr = "#909090";
								if(index === 1){
									icn = rate >= 2 ? faCircle : faCircleDot;
									clr = rate >= 2 ? "#909090" : "#aeaeae";
								}else if(index === 2){
									icn = rate >= 4 ? faCircle : faCircleDot;
									clr = rate >= 4 ? "#909090" : "#aeaeae";
								}else if(index === 3){
									icn = rate >= 6 ? faCircle : faCircleDot;
									clr = rate >= 6 ? "#909090" : "#aeaeae";
								}else if(index === 4){
									icn = rate >= 8 ? faCircle : faCircleDot;
									clr = rate >= 8 ? "#909090" : "#aeaeae";
								}else if(index === 5){
									icn = rate >= 9 ? faCircle : faCircleDot;
									clr = rate >= 9 ? "#909090" : "#aeaeae";
								}
								return (
									<span key={`ftaw-${index}`}>
										<FontAwesomeIcon icon={icn} style={{fontSize:"0.65rem", color:clr}} />&nbsp;
									</span>
								);
							})
						}
						</span>),
					time: year > 1 ? `${year} ${Trtor('skills', 'year')}s` : `${year} ${Trtor('skills', 'year')}`
				});
			}
		}

		this.sum = sum;		
		this.setFound(count);
		return ret;
	}
	getSummary(){
		let ret = [];
		this.sum.forEach((summary, ind) => {
			ret.push(
				<div className='rsum-sum-bx' key={`rsum-sum-${ind}`}>
					<div className="rsum-sum-lft">
						<div className="rsum-sum-tag">{summary.role}</div>
					</div>
					<div className="rsum-sum-rgt pd-t3">
						{summary.tech}
					</div>
					<div className="clr"></div>
					<div className='rsum-sum-tech'>
						<div className="rsum-sum-tahg fltl" style={{fontSize:"0.8rem", padding:"0 0 0 2px"}}>{summary.time}</div>
						<div className='clr'></div>
					</div>
				</div>
			);
		});
		return ret;
	}
	updateFilter(str, from){
		if(from === "header"){
			this.setState({typing: from});
		}else{
			this.setState({typing: from}, () => {
				this.setState({filter: str}, () => {
					this.getExp();
				});
			});
		}
	}
  	render(){		
    	return (
      		<div style={{background:"#fff"}}>
				<Header menu={Trtor('head', 'menu.skills')} query={(value, from) => this.updateFilter(value, from)} />
				<div className="gen-sb-hd">
					<div className='gen-mid'>
						{this.getName()}
					</div>
				</div>
				<div className='gen-mid mg-t20 mg-b40'>
					<div className="rsum-lft">							
						{this.getTitle()}
						<div className='rsum-src-bx'>
							<Highlight count={this.state.found} param={this.state.query} uptQuery={(value, from) => this.updateFilter(value, from)} />
						</div>
						<div className='rsum-exp-nm'>
							{Trtor('skills', 'applied')}
						</div>
						{this.getExp()}						
						<div className="clr"></div>					
					</div>
					<div className="rsum-rgt">
						<div className="rsum-ftr">
							<Highlight count={this.state.found} param={this.state.query} uptQuery={(value, from) => this.updateFilter(value, from)} />												
						</div>
						<div className="rsum-sum">
							<span style={{fontWeight:"500"}}>
								{Trtor('skills', 'summary')}
							</span>
							{this.getSummary()}									
						</div>
					</div>
					<div className="clr"></div>						
				</div>					
				<Footer />
      		</div>
		);
	}
}
export default withParamsRouter(Skills);