import { TECH_NM, TECH } from './NameTech';
import { COMPANY } from './Company';
export const RESUME_DATA = {
	en:[{
			role: `${TECH_NM.en.fullstack} ${TECH_NM.en.developer}`,
			timeStart: COMPANY.en.desjardins.timeStart,
			timeEnd: COMPANY.en.desjardins.timeEnd,
			company: COMPANY.en.desjardins.company,
			field: COMPANY.en.desjardins.field,
			city: COMPANY.en.desjardins.city,
			country: COMPANY.en.desjardins.country,
			desc: COMPANY.en.desjardins.desc,
			techEnv: [TECH_NM.en.git,TECH_NM.en.visualstudio,TECH_NM.en.intellij,TECH_NM.en.windows,TECH_NM.en.jira,TECH_NM.en.confluence,TECH_NM.en.azure],
			techSkill: [TECH_NM.en.reactjs,TECH_NM.en.typescript,TECH_NM.en.javascript, TECH_NM.en.nodejs,TECH_NM.en.jest, TECH_NM.en.html5],
			task: [
				"Provide guidelines for testing a large application by using features testing instead of unit testing.",
				"Create and update baseline guidelines for front end accessibility for multiple web applications.",
				"Collaborate with designers and managers to transform creative concepts into production realities.",
				"Create and update policy documents and technical guides for developers.",
				"Perform code reviews, refactor crucial parts of applications to improve code quality."
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.en.fullstack} ${TECH_NM.en.developer}`,
			timeStart: COMPANY.en.jpcolonna.timeStart,
			timeEnd: COMPANY.en.jpcolonna.timeEnd,
			company: COMPANY.en.jpcolonna.company,
			field: COMPANY.en.jpcolonna.field,
			city: COMPANY.en.jpcolonna.city,
			country: COMPANY.en.jpcolonna.country,
			desc: COMPANY.en.jpcolonna.desc,
			techEnv: [TECH_NM.en.git,TECH_NM.en.ubuntu,TECH_NM.en.visualstudio,TECH_NM.en.redmine],
			techSkill: [TECH_NM.en.reactjs,TECH_NM.en.javascript,TECH_NM.en.expressjs,TECH_NM.en.mongodb, TECH_NM.en.nodejs, TECH_NM.en.html5,TECH_NM.en.css3],
			task: [
				"Redesigned the home page to fit and highlight the products provided to increase engagement.",
				"Analyzed and enhanced performance of the application by eliminating duplicate and unnecessary request made to the Google's API, which helped improve performance by 11 percent.",
				"Collaborated with designers and other engineers to ameliorate the newsletters, which resulted in more positive response.",
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.en.fullstack} ${TECH_NM.en.developer}`,
			timeStart: COMPANY.en.frenchgovernment.timeStart,
			timeEnd: COMPANY.en.frenchgovernment.timeEnd,
			company: COMPANY.en.frenchgovernment.company,
			field: COMPANY.en.frenchgovernment.field,
			city: COMPANY.en.frenchgovernment.city,
			country: COMPANY.en.frenchgovernment.country,
			desc: COMPANY.en.frenchgovernment.desc,
			techEnv: [TECH_NM.en.symfony,TECH_NM.en.git,TECH_NM.en.nodejs,TECH_NM.en.visualstudio,TECH_NM.en.centos],
			techSkill: [TECH_NM.en.reactjs, TECH_NM.en.nodejs, TECH_NM.en.php,TECH_NM.en.javascript,TECH_NM.en.postgresql,TECH_NM.en.bootstrap,TECH_NM.en.html5,TECH_NM.en.css3],
			task: [
				"Built and delivered technical solutions according to client's requirements.",
				"Cooperated with designers to increase performance of highly viewed pages.",
				"Advised and assisted about technology and standards such as \"ORM\" to increase code quality."
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.en.fullstack} ${TECH_NM.en.developer}`,
			timeStart: COMPANY.en.fdj.timeStart,
			timeEnd: COMPANY.en.fdj.timeEnd,
			company: COMPANY.en.fdj.company,
			field: COMPANY.en.fdj.field,
			city: COMPANY.en.fdj.city,
			country: COMPANY.en.fdj.country,
			desc: COMPANY.en.fdj.desc,
			techEnv: [TECH_NM.en.springboot,TECH_NM.en.git,TECH_NM.en.eclipse,TECH_NM.en.jenkins,TECH_NM.en.tagcommander, TECH_NM.en.swagger, TECH_NM.en.jira, TECH_NM.en.macos],
			techSkill: [TECH_NM.en.java,TECH_NM.en.bootstrap,TECH_NM.en.javascript,TECH_NM.en.jquery,TECH_NM.en.html5,TECH_NM.en.css3],
			task: [
				"Delivered high-quality, robust production code for a diverse array of projects.",
				"Identified performance issues when inserting data from files containing more than 30 millions entries, which were improved by using shell scripts.",
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.en.fullstack} ${TECH_NM.en.developer}`,
			timeStart: COMPANY.en.orange.timeStart,
			timeEnd: COMPANY.en.orange.timeEnd,
			company: COMPANY.en.orange.company,
			field: COMPANY.en.orange.field,
			city: COMPANY.en.orange.city,
			country: COMPANY.en.orange.country,
			desc: COMPANY.en.orange.desc,
			techEnv: [TECH_NM.en.symfony,TECH_NM.en.git,TECH_NM.en.phpstorm,TECH_NM.en.jenkins,TECH_NM.en.tuleap, TECH_NM.en.unittest, TECH_NM.en.centos],
			techSkill: [TECH_NM.en.php,TECH_NM.en.mysql,TECH_NM.en.bootstrap,TECH_NM.en.fontawesome,TECH_NM.en.jquery,TECH_NM.en.javascript, TECH_NM.en.html5, TECH_NM.en.css3],
			task: [
				"Proposed and implemented scalable error logging solutions to issues identified with the services.",
				"Led, helped and facilitated accessibility initiatives with technical and product documentations.",
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.en.fullstack} ${TECH_NM.en.developer}`,
			timeStart: COMPANY.en.afml.timeStart,
			timeEnd: COMPANY.en.afml.timeEnd,
			company: COMPANY.en.afml.company,
			field: COMPANY.en.afml.field,
			city: COMPANY.en.afml.city,
			country: COMPANY.en.afml.country,
			desc: COMPANY.en.afml.desc,
			techEnv: [TECH_NM.en.springmvc,TECH_NM.en.thymeleaf,TECH_NM.en.svn,TECH_NM.en.netbeans,TECH_NM.en.redmine, TECH_NM.en.centos],
			techSkill: [TECH_NM.en.java,TECH_NM.en.mysql,TECH_NM.en.jquery,TECH_NM.en.jquery,TECH_NM.en.javascript, TECH_NM.en.html5, TECH_NM.en.css3],
			task: [
				"Introduced MySQL Views to the project, which helped provide a robust production code.",
				"Architected and implemented the user interface with custom user access control to better fit the requirements of the project.",
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.en.fullstack} ${TECH_NM.en.developer}`,
			timeStart: COMPANY.en.freelance.timeStart,
			timeEnd: COMPANY.en.freelance.timeEnd,
			company: COMPANY.en.freelance.company,
			field: COMPANY.en.freelance.field,
			city: COMPANY.en.freelance.city,
			country: COMPANY.en.freelance.country,
			desc: COMPANY.en.freelance.desc,
			techEnv: [TECH_NM.en.joomla,TECH_NM.en.eclipse,TECH_NM.en.notepad,TECH_NM.en.phpmyadmin,TECH_NM.en.filezilla, TECH_NM.en.cpanel],
			techSkill: [TECH_NM.en.php,TECH_NM.en.mysql,TECH_NM.en.javascript,TECH_NM.en.css,TECH_NM.en.html],
			task: [
				`Developed, maintained, and shipped production code for client websites primarily using ${TECH_NM.en.html}, ${TECH_NM.en.css}, ${TECH_NM.en.javascript}, ${TECH_NM.en.jquery} and ${TECH_NM.en.php}.`,
				"Performed quality assurance tests on various sites to ensure cross browser compatibility.",
			],
			active: ['any']
		},
		{
			role: COMPANY.en.personal.name,
			timeStart: COMPANY.en.personal.timeStart,
			timeEnd: COMPANY.en.personal.timeEnd,
			company: COMPANY.en.personal.name,
			field: COMPANY.en.personal.name,
			city: COMPANY.en.personal.name,
			country: COMPANY.en.personal.name,
			desc: COMPANY.en.personal.name,
			techEnv: [TECH_NM.en.visualstudio,TECH_NM.en.eclipse,TECH_NM.en.notepad,TECH_NM.en.phpmyadmin,TECH_NM.en.filezilla, TECH_NM.en.cpanel],
			techSkill: [TECH_NM.en.reactjs,TECH_NM.en.java,TECH_NM.en.php,TECH_NM.en.mysql,TECH_NM.en.javascript,TECH_NM.en.css,TECH_NM.en.html],
			task: [
				COMPANY.en.personal.name
			],
			active: ['skill']
		}
	],
	fr:[
		{
			role: `${TECH_NM.fr.developer} ${TECH_NM.fr.fullstack}`,
			timeStart: COMPANY.fr.desjardins.timeStart,
			timeEnd: COMPANY.fr.desjardins.timeEnd,
			company: COMPANY.fr.desjardins.company,
			field: COMPANY.fr.desjardins.field,
			city: COMPANY.fr.desjardins.city,
			country: COMPANY.fr.desjardins.country,
			desc: COMPANY.fr.desjardins.desc,
			techEnv: [TECH_NM.fr.git,TECH_NM.fr.visualstudio,TECH_NM.fr.intellij,TECH_NM.fr.windows,TECH_NM.fr.jira,TECH_NM.fr.confluence,TECH_NM.fr.azure],
			techSkill: [TECH_NM.fr.reactjs,TECH_NM.fr.typescript,TECH_NM.fr.javascript, TECH_NM.fr.nodejs,TECH_NM.fr.jest, TECH_NM.fr.html5],
			task: [
				"Conseiller et fournir des directives spécifiques pour tester de grandes applications en utilisant des tests de fonctionnalités.",
				"Créer et mettre à jour des directives de base pour l'accessibilité Front end pour plusieurs applications Web.",
				"Collaborer avec les designers et les chargés de projet pour transformer les concepts créatifs en réalités de production.",
				"Créer et mettre à jour des documents de stratégie et des guides techniques pour les développeurs.",
				"Effectuer des révisions de code et refactorisation des éléments de code cruciaux des applications."
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.fr.developer} ${TECH_NM.fr.fullstack}`,
			timeStart: COMPANY.fr.jpcolonna.timeStart,
			timeEnd: COMPANY.fr.jpcolonna.timeEnd,
			company: COMPANY.fr.jpcolonna.company,
			field: COMPANY.fr.jpcolonna.field,
			city: COMPANY.fr.jpcolonna.city,
			country: COMPANY.fr.jpcolonna.country,
			desc: COMPANY.fr.jpcolonna.desc,
			techEnv: [TECH_NM.fr.git,TECH_NM.fr.ubuntu,TECH_NM.fr.visualstudio,TECH_NM.fr.redmine],
			techSkill: [TECH_NM.fr.reactjs,TECH_NM.fr.javascript,TECH_NM.fr.expressjs,TECH_NM.fr.mongodb, TECH_NM.fr.nodejs, TECH_NM.fr.html5,TECH_NM.fr.css3],
			task: [
				"Refonte de la page d'accueil pour s'adapter et mettre en valeur les services de l'entreprise afin d'obtenir plus d'engagement des visiteurs.",
				"Analyse et augmentation des performances de l'application Web de 11 pourcent en éliminant les requêtes en double et inutiles adressées à l'API de Google.",
				"Collaboration avec des concepteurs et d'autres ingénieurs pour améliorer les modèles de la newsletter et fournir des informations de qualités.",
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.fr.developer} ${TECH_NM.fr.fullstack}`,
			timeStart: COMPANY.fr.frenchgovernment.timeStart,
			timeEnd: COMPANY.fr.frenchgovernment.timeEnd,
			company: COMPANY.fr.frenchgovernment.company,
			field: COMPANY.fr.frenchgovernment.field,
			city: COMPANY.fr.frenchgovernment.city,
			country: COMPANY.fr.frenchgovernment.country,
			desc: COMPANY.fr.frenchgovernment.desc,
			techEnv: [TECH_NM.fr.symfony,TECH_NM.fr.git,TECH_NM.fr.nodejs,TECH_NM.fr.visualstudio,TECH_NM.fr.centos],
			techSkill: [TECH_NM.fr.reactjs, TECH_NM.fr.nodejs, TECH_NM.fr.php,TECH_NM.fr.javascript,TECH_NM.fr.postgresql,TECH_NM.fr.bootstrap,TECH_NM.fr.html5,TECH_NM.fr.css3],
			task: [
				"Construire et livrer des solutions techniques selon les exigences du client.",
				"Coopéré avec les concepteurs pour fournir et mettre à jour le contenu des pages cruciales de l'application afin d'augmenter leur qualité.",
				"Conseiller et assister d'autres ingénieurs sur les technologies actuels et les normes de développement."
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.fr.developer} ${TECH_NM.fr.fullstack}`,
			timeStart: COMPANY.fr.fdj.timeStart,
			timeEnd: COMPANY.fr.fdj.timeEnd,
			company: COMPANY.fr.fdj.company,
			field: COMPANY.fr.fdj.field,
			city: COMPANY.fr.fdj.city,
			country: COMPANY.fr.fdj.country,
			desc: COMPANY.fr.fdj.desc,
			techEnv: [TECH_NM.fr.springboot,TECH_NM.fr.git,TECH_NM.fr.eclipse,TECH_NM.fr.jenkins,TECH_NM.fr.tagcommander, TECH_NM.fr.swagger, TECH_NM.fr.jira, TECH_NM.fr.macos],
			techSkill: [TECH_NM.fr.java,TECH_NM.fr.bootstrap,TECH_NM.fr.javascript,TECH_NM.fr.jquery,TECH_NM.fr.html5,TECH_NM.fr.css3],
			task: [
				"Fournir un code de production robuste et de haute qualité pour un large éventail de projets.",
				"Identifié des problèmes de performances lors de l'insertion de données à partir de fichiers contenant plus de 30 millions d'entrées, ce qui a été amélioré en utilisant des scripts shell.",
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.fr.developer} ${TECH_NM.fr.fullstack}`,
			timeStart: COMPANY.fr.orange.timeStart,
			timeEnd: COMPANY.fr.orange.timeEnd,
			company: COMPANY.fr.orange.company,
			field: COMPANY.fr.orange.field,
			city: COMPANY.fr.orange.city,
			country: COMPANY.fr.orange.country,
			desc: COMPANY.fr.orange.desc,
			techEnv: [TECH_NM.fr.symfony,TECH_NM.fr.git,TECH_NM.fr.phpstorm,TECH_NM.fr.jenkins,TECH_NM.fr.tuleap, TECH_NM.fr.unittest, TECH_NM.fr.centos],
			techSkill: [TECH_NM.fr.php,TECH_NM.fr.mysql,TECH_NM.fr.bootstrap,TECH_NM.fr.fontawesome,TECH_NM.fr.jquery,TECH_NM.fr.javascript, TECH_NM.fr.html5, TECH_NM.fr.css3],
			task: [
				"Proposer et mettre en œuvre des solutions évolutives d'enregistrement des erreurs aux problèmes identifiés avec les différents services utilisés pour communiquer avec d'autres serveurs.",
				"Dirigé, aidé et facilité des initiatives d'accessibilité à l'échelle de l'entreprise telles que des documentations techniques et de produits.",
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.fr.developer} ${TECH_NM.fr.fullstack}`,
			timeStart: COMPANY.fr.afml.timeStart,
			timeEnd: COMPANY.fr.afml.timeEnd,
			company: COMPANY.fr.afml.company,
			field: COMPANY.fr.afml.field,
			city: COMPANY.fr.afml.city,
			country: COMPANY.fr.afml.country,
			desc: COMPANY.fr.afml.desc,
			techEnv: [TECH_NM.fr.springmvc,TECH_NM.fr.thymeleaf,TECH_NM.fr.svn,TECH_NM.fr.netbeans,TECH_NM.fr.redmine, TECH_NM.fr.centos],
			techSkill: [TECH_NM.fr.java,TECH_NM.fr.mysql,TECH_NM.fr.jquery,TECH_NM.fr.jquery,TECH_NM.fr.javascript, TECH_NM.fr.html5, TECH_NM.fr.css3],
			task: [
				"Introduit le concept de SQL Views dans le projet, ce qui a permis de fournir un code de production robuste pour les fonctionnalités de requête MySQL.",
				"Concevoir et mettre en œuvre l'interface utilisateur avec un contrôle d'accès utilisateur personnalisé pour mieux répondre aux exigences du projet.",
			],
			active: ['any']
		},
		{
			role: `${TECH_NM.fr.developer} ${TECH_NM.fr.fullstack}`,
			timeStart: COMPANY.fr.freelance.timeStart,
			timeEnd: COMPANY.fr.freelance.timeEnd,
			company: COMPANY.fr.freelance.company,
			field: COMPANY.fr.freelance.field,
			city: COMPANY.fr.freelance.city,
			country: COMPANY.fr.freelance.country,
			desc: COMPANY.fr.freelance.desc,
			techEnv: [TECH_NM.fr.joomla,TECH_NM.fr.eclipse,TECH_NM.fr.notepad,TECH_NM.fr.phpmyadmin,TECH_NM.fr.filezilla, TECH_NM.fr.cpanel],
			techSkill: [TECH_NM.fr.php,TECH_NM.fr.mysql,TECH_NM.fr.javascript,TECH_NM.fr.css,TECH_NM.fr.html],
			task: [
				`Développer, maintenir et expédier le code de production pour les sites Web des clients en utilisant principalement ${TECH_NM.fr.html}, ${TECH_NM.fr.css}, ${TECH_NM.fr.javascript}, ${TECH_NM.fr.jquery} and ${TECH_NM.fr.php}.`,
				"Effectuer des tests d'assurance qualité sur divers sites pour assurer la compatibilité entre les navigateurs.",
			],
			active: ['any']
		},
		{
			role: COMPANY.fr.personal.name,
			timeStart: COMPANY.fr.personal.timeStart,
			timeEnd: COMPANY.fr.personal.timeEnd,
			company: COMPANY.fr.personal.name,
			field: COMPANY.fr.personal.name,
			city: COMPANY.fr.personal.name,
			country: COMPANY.fr.personal.name,
			desc: COMPANY.fr.personal.name,
			techEnv: [TECH_NM.fr.visualstudio,TECH_NM.fr.eclipse,TECH_NM.fr.notepad,TECH_NM.fr.phpmyadmin,TECH_NM.fr.filezilla, TECH_NM.fr.cpanel],
			techSkill: [TECH_NM.fr.reactjs,TECH_NM.fr.java,TECH_NM.fr.php,TECH_NM.fr.mysql,TECH_NM.fr.javascript,TECH_NM.fr.css,TECH_NM.fr.html],
			task: [
				COMPANY.fr.personal.name
			],
			active: ['skill']
		}
	]
};
export const MAIN_SKILL = {
	frontend:[
		TECH_NM.en.javascript,
		TECH_NM.en.reactjs,
		TECH_NM.en.html,
		TECH_NM.en.html5,
		TECH_NM.en.css,
		TECH_NM.en.css3,
	],
	backend:[
		TECH_NM.en.java,
		TECH_NM.en.expressjs,
		TECH_NM.en.php,
		TECH_NM.en.nodejs,
	],
	database:[
		TECH_NM.en.mysql,
		TECH_NM.en.postgresql,
		TECH_NM.en.mongodb,
		TECH_NM.en.sql
	],
	framework:[
		TECH_NM.en.springboot,
		TECH_NM.en.springmvc,
		TECH_NM.en.symfony,
		TECH_NM.en.swagger
	],
	libs:[
		/*TECH_NM.en.reactjs,*/
		/*TECH_NM.en.expressjs,*/
		TECH_NM.en.jquery,
		TECH_NM.en.bootstrap,
		TECH_NM.en.fontawesome,
		TECH_NM.en.tagcommander,
	],
	version:[
		TECH_NM.en.git,
		TECH_NM.en.svn
	],
	system:[
		TECH_NM.en.centos,
		TECH_NM.en.ubuntu,
		TECH_NM.en.windows
	],
	ide:[
		TECH_NM.en.visualstudio,
		TECH_NM.en.intellij,
		TECH_NM.en.phpstorm,
		TECH_NM.en.netbeans,
		TECH_NM.en.eclipse,
		TECH_NM.en.notepad
	],
	project:[
		TECH_NM.en.jira,
		TECH_NM.en.confluence,
		TECH_NM.en.redmine,
		TECH_NM.en.tuleap
	],
	methodlogy:[
		TECH_NM.en.scrum
	],
	continuousintegration:[
		TECH_NM.en.azure,
		TECH_NM.en.jenkins
	],
	test:[
		TECH_NM.en.jest,
		TECH_NM.en.phpunit,
		TECH_NM.en.junit,
		TECH_NM.en.springboottest
	]
};
export const HOME_SKILL = {
	frontend:[
		TECH.javascript,
		TECH.reactjs,
		TECH.html,
		TECH.css,
	],
	backend:[
		TECH.java,
		TECH.expressjs,
		TECH.php,
		TECH.nodejs,
	],
	database:[
		TECH.mysql,
		TECH.postgresql,
		TECH.mongodb,
		TECH.sql
	],
};
export const MAIN_SKILL_SUM = [TECH_NM.en.javascript, TECH_NM.en.reactjs, TECH_NM.en.java, TECH_NM.en.php, TECH_NM.en.css, TECH_NM.en.css3];